<template>
    <div data-id="MarketShare" class="absolute inset-0">
        <div class="absolute bottom-0 left-0 right-56 top-0">
            <div class="absolute left-0 right-0 top-0">
                <div class="h-28 px-8 py-4 text-slate-500">
                    <h3 class="mt-1 font-brand">Covered Lives</h3>
                    <p class="text-5xl font-bold leading-none">
                        {{ format(data.coveredLives.total) }}
                    </p>
                </div>
                <div class="mx-8 h-6 rounded bg-slate-500" />
            </div>

            <div class="absolute bottom-0 left-0 right-0 top-[8.5rem] overflow-x-hidden overflow-y-scroll pt-16">
                <div id="top" class="absolute left-8 right-8 top-4">
                    <p class="pb-1">
                        <span class="pr-3 text-sm font-semibold text-violet-700">{{
                            formatPercent(data.coveredLives.commercialRisk.share)
                        }}</span>
                    </p>
                    <div
                        class="flex h-6 items-center rounded border-r border-white bg-violet-700 pl-12"
                        :style="{
                            width: formatPercent(data.coveredLives.commercialRisk.share),
                        }"
                    />
                </div>
                <div class="border-red absolute left-8 right-8 top-4 overflow-visible pb-6 text-right">
                    <p class="pb-1">
                        <span class="pl-3 text-right text-sm font-semibold text-fuchsia-700">{{
                            formatPercent(data.coveredLives.asoEnrollment.share)
                        }}</span>
                    </p>
                    <div
                        class="absolute bottom-0 right-0 flex h-6 items-center justify-end rounded border-l border-white bg-fuchsia-700"
                        :style="{
                            width: formatPercent(data.coveredLives.asoEnrollment.share),
                        }"
                    />
                </div>

                <div class="mt-3 flex px-8">
                    <div class="w-1/2 text-violet-700">
                        <h4 class="font-brand text-sm">Fully Insured</h4>
                        <p class="text-3xl font-bold">
                            {{ format(data.coveredLives.commercialRisk.total) }}
                        </p>
                    </div>
                    <div class="w-1/2 text-right text-fuchsia-700">
                        <h4 class="font-brand text-sm">Self Insured</h4>
                        <p class="text-3xl font-bold">
                            {{ format(data.coveredLives.asoEnrollment.total) }}
                        </p>
                    </div>
                </div>

                <h5 class="relative mb-4 mt-4 text-center font-brand text-xl">Insurers Share</h5>
                <div class="border-b" />

                <div class="mt-8">
                    <div class="mx-8 flex h-12 overflow-hidden rounded bg-slate-100" data-id="totalShareBreakdown">
                        <template v-for="share in data.insurers" :key="`share_${share.name}`">
                            <div
                                v-if="share.coveredLives.share > 3"
                                :style="{ width: formatPercent(share.coveredLives.share) }"
                                class="colorize flex-grow-1 flex flex-shrink-0 cursor-pointer items-center overflow-hidden rounded border-r-2 border-white"
                                @click="scrollToInsurer(share.name)"
                            >
                                <div class="mx-2 w-full truncate bg-transparent font-brand text-xs text-white">
                                    {{ share.name }}
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="mt-8 border-b" />

                <div
                    v-for="insurer in data.insurers"
                    :id="insurer.name"
                    :key="insurer.name"
                    class="relative mt-10 border-b pb-2 pt-8"
                >
                    <div class="absolute left-8 right-8 top-0 pb-4">
                        <p class="pb-1">
                            <span class="pr-3 text-sm font-semibold text-teal-600">{{
                                formatPercent(insurer.coveredLives.share)
                            }}</span>
                        </p>
                        <div
                            class="absolute bottom-0 left-0 flex h-4 items-center rounded bg-teal-600"
                            :style="{ width: formatPercent(insurer.coveredLives.share) }"
                        />
                    </div>

                    <h5 class="mt-6 px-8 font-brand text-2xl font-semibold text-teal-900">
                        {{ insurer.name }}
                    </h5>
                    <div class="mx-8">
                        <div class="py-4 pb-2 text-sm text-white">
                            <h3 class="font-brand text-slate-500">Covered Lives</h3>
                            <p class="text-3xl font-bold text-slate-500">
                                {{ format(insurer.coveredLives.total) }}
                            </p>
                        </div>
                        <div class="h-2 rounded-sm bg-slate-500" />
                    </div>
                    <div class="relative mx-8 pb-8 pt-10">
                        <div
                            class="absolute left-0 top-0 h-12 pt-2"
                            :style="{
                                width: formatPercent(insurer.coveredLives.commercialRisk.share),
                            }"
                        >
                            <span class="text-xs font-semibold text-violet-700">{{
                                formatPercent(insurer.coveredLives.commercialRisk.share)
                            }}</span>
                            <div class="h-2 w-full rounded-sm border-r bg-violet-700" />
                        </div>
                        <div
                            class="absolute right-0 top-0 h-12 pt-2 text-right"
                            :style="{
                                width: formatPercent(insurer.coveredLives.asoEnrollment.share),
                            }"
                        >
                            <span class="text-right text-sm text-xs font-semibold text-fuchsia-700">{{
                                formatPercent(insurer.coveredLives.asoEnrollment.share)
                            }}</span>
                            <div class="h-2 w-full rounded-sm border-r bg-fuchsia-700" />
                        </div>
                        <div class="mt-2 flex">
                            <div class="w-1/2 text-violet-700">
                                <h4 class="font-brand text-xs">Fully Insured</h4>
                                <p class="text-xl font-bold">
                                    {{ format(insurer.coveredLives.commercialRisk.total) }}
                                </p>
                            </div>
                            <div class="w-1/2 text-right text-fuchsia-700">
                                <h4 class="font-brand text-xs">Self Insured</h4>
                                <p class="text-xl font-bold">
                                    {{ format(insurer.coveredLives.asoEnrollment.total) }}
                                </p>
                            </div>
                        </div>

                        <div v-if="insurer.topStates.length" class="mt-4 flex items-center font-brand text-sm">
                            <span class="opacity-50">Top States:</span>
                            <span v-for="state in insurer.topStates" :key="state" class="ml-3 rounded border px-3 py-2">
                                {{ state }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MarketShareNav class="absolute bottom-0 right-0 top-0 w-52" :states="states" :insurers="activeInsurers" />
    </div>
</template>

<script setup>
import { watch, onMounted, nextTick, computed } from 'vue'
import { useRoute } from 'vue-router'

import MarketShareNav from '@/components/market-share/MarketShareNav.vue'

import { useNumberFormatter } from '@/composables/numberFormatter'

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
    states: {
        type: Array,
        required: true,
    },
    insurers: {
        type: Array,
        required: true,
    },
})

const route = useRoute()
const { format, formatPercent } = useNumberFormatter()

const activeInsurers = computed(() => props.data.insurers.map((i) => i.name).sort((a, b) => a.localeCompare(b)))

function scrollToInsurer(id) {
    if (!id) return

    const insurer = document.getElementById(id)
    if (!insurer) return

    insurer.scrollIntoView({ behavior: 'smooth' })
}

watch(
    () => route.query.insurer,
    () => scrollToInsurer(route.query.insurer)
)

watch(
    () => route.query.state,
    () => scrollToInsurer('top')
)

onMounted(async () => {
    await nextTick()
    scrollToInsurer(route.query.insurer)
})
</script>

<style scoped>
[data-id='totalShareBreakdown'] div.colorize:nth-child(1) {
    @apply bg-teal-900;
}
[data-id='totalShareBreakdown'] div.colorize:nth-child(2) {
    @apply bg-teal-800;
}
[data-id='totalShareBreakdown'] div.colorize:nth-child(3) {
    @apply bg-teal-700;
}

[data-id='totalShareBreakdown'] div.colorize:nth-child(4) {
    @apply bg-teal-600;
}

[data-id='totalShareBreakdown'] div.colorize:nth-child(5) {
    @apply bg-teal-500;
}

[data-id='totalShareBreakdown'] div.colorize:nth-child(6) {
    @apply bg-teal-400;
}

[data-id='totalShareBreakdown'] div.colorize:nth-child(7) {
    @apply bg-teal-300;
}

[data-id='totalShareBreakdown'] div.colorize:nth-child(8) {
    @apply bg-teal-200;
}
</style>
