<template>
    <div
        :class="{ 'pointer-events-none opacity-40': disabled }"
        data-id="ComboboxControl"
        @keyup.enter="selectActive"
        @keyup.down="moveActiveDown"
        @keyup.up="moveActiveUp"
    >
        <Combobox v-slot="{ open }" as="div" :model-value="modelValue" :disabled="disabled" :placeholder="placeholder">
            <ComboboxLabel class="block text-sm font-medium text-gray-700">
                {{ label }}
            </ComboboxLabel>

            <ComboboxButton as="div" class="relative">
                <ComboboxInput
                    :class="{
                        'pr-16': allowEmpty && modelValue,
                        'pr-12': !(allowEmpty && modelValue),
                    }"
                    class="h-8 w-full rounded border border-slate-300 bg-white pl-3 text-brand-font-amy focus:border-brand-blue/60 focus:outline-none focus:ring-4 focus:ring-brand-blue/10 sm:text-[12px]"
                    :placeholder="placeholder"
                    :name="name"
                    :autocomplete="`disable_for_${name}`"
                    :display-value="(option) => option?.name"
                    @change="query = $event.target.value"
                    @focus="handleInputFocus"
                />
                <div @click.stop>
                    <button
                        v-if="allowEmpty && modelValue"
                        type="button "
                        class="absolute inset-y-0 right-8 flex w-10 items-center justify-end text-gray-600 hover:text-brand-blue"
                        @click="removeSelection"
                    >
                        <span class="inline-flex rounded-full bg-white/60 p-1.5">
                            <XCircleIcon class="h-auto w-5 opacity-40" />
                        </span>
                    </button>
                    <ComboboxButton
                        class="absolute inset-y-0 right-0 flex w-10 items-center justify-center text-slate-500 hover:text-slate-900 focus:outline-none"
                    >
                        <ChevronUpDownIcon class="h-5 w-5" aria-hidden="true" />
                    </ComboboxButton>
                </div>
                <ComboboxOptions
                    v-if="open"
                    ref="elOptions"
                    :class="[optionsWidth]"
                    class="absolute left-0 z-10 mt-1 max-h-60 overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-[12px]"
                >
                    <li
                        v-for="(option, index) in filteredOptions"
                        :key="option.id"
                        :class="{
                            'pointer-events-none opacity-40': !isAvailable(option),
                            'bg-brand-blue bg-opacity-10 text-brand-blue': option.id === modelValue?.id,
                            'hover:bg-brand-beige-amy': option.id !== modelValue?.id,
                            'bg-brand-beige-amy': index === activeIndex,
                        }"
                        :data-option="option.id"
                        :data-index="index"
                        class="relative cursor-default select-none py-2 pl-3 pr-10"
                        @click="setSelectedOption(option)"
                    >
                        <div class="flex">
                            <span
                                :class="{
                                    'font-semibold': option.id === modelValue?.id,
                                }"
                                class="truncate"
                            >
                                {{ option.name }}
                            </span>
                        </div>
                        <span
                            v-if="option.id === modelValue?.id"
                            :class="{
                                'text-white': option.id !== modelValue?.id,
                            }"
                            class="absolute inset-y-0 right-0 flex items-center pr-4"
                        >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                    <li v-if="!filteredOptions.length">
                        <p
                            class="relative cursor-default select-none bg-slate-50 py-2 pl-3 pr-9 font-semibold text-slate-400"
                        >
                            Not found
                        </p>
                    </li>
                    <div ref="optionsLoad" />
                </ComboboxOptions>
            </ComboboxButton>
        </Combobox>
    </div>
</template>

<script setup>
import { computed, ref, watch, nextTick } from 'vue'
import { CheckIcon, ChevronUpDownIcon, XCircleIcon } from '@heroicons/vue/20/solid'
import { Combobox, ComboboxButton, ComboboxInput, ComboboxLabel, ComboboxOptions } from '@headlessui/vue'

const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    name: {
        type: String,
        default: () => `select_control_input_${new Date().valueOf()}`,
    },
    modelValue: {
        type: Object,
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    allowEmpty: {
        type: Boolean,
        default: false,
    },
    optionsWidth: {
        type: String,
        default: 'w-full',
    },
    placeholder: {
        type: String,
        default: null,
    },
    available: {
        type: Array,
        default: null,
    },
})

const emit = defineEmits(['update:modelValue'])

const query = ref('')
const elOptions = ref()
const optionsLoad = ref()
const activeIndex = ref(0)

const filteredOptions = computed(() => {
    if (query.value && query.value.length) {
        const lowerCaseQuery = query.value.toLowerCase()
        return props.data.filter((option) => {
            return option.name?.toLowerCase().indexOf(lowerCaseQuery) !== -1
        })
    }

    return props.data
})

function isAvailable(option) {
    if (!props.available) return true

    return props.available?.some((o) => o.id === option.id)
}

function setSelectedOption(option) {
    emit('update:modelValue', option)
}

async function removeSelection() {
    emit('update:modelValue', null)

    await nextTick()

    query.value = null
    activeIndex.value = 0
}

function selectActive() {
    const active = filteredOptions.value[activeIndex.value]

    if (active) {
        emit('update:modelValue', active)
    }
}

function moveActiveDown() {
    let nextIndex = activeIndex.value + 1

    if (nextIndex >= filteredOptions.value.length - 1) {
        nextIndex = filteredOptions.value.length - 1
    }

    activeIndex.value = nextIndex
    scrollActiveIntoView()
}

function moveActiveUp() {
    let nextIndex = activeIndex.value - 1
    if (nextIndex < 1) {
        nextIndex = 0
    }

    activeIndex.value = nextIndex
    scrollActiveIntoView()
}

function scrollActiveIntoView() {
    const activeEl = document.querySelector(`[data-index="${activeIndex.value}"]`)

    activeEl?.scrollIntoView(false)
}

function prepareOptions() {
    // Find and scroll to current Option
    if (props.modelValue) {
        const activeOption = filteredOptions.value.find((o) => o.id === props.modelValue.id)
        const activeEl = document.querySelector(`[data-option="${activeOption?.id}"]`)

        if (activeEl) {
            activeIndex.value = Number(activeEl.dataset.index)
            scrollActiveIntoView()
        }
    }
}

function handleInputFocus(event) {
    // Select any text in input
    setTimeout(() => event.target.select(), 100)
}

watch(
    () => optionsLoad.value,
    (el) => {
        if (el) {
            prepareOptions()
        }
    }
)
</script>
