<template>
    <div class="flex flex-col py-4 pr-4">
        <div class="flex h-1/2 flex-shrink-0 flex-grow-0 flex-col rounded-t border">
            <h2 class="-mx-2 border-b px-6 pb-2 pt-2 font-brand text-xs font-semibold uppercase opacity-50">States</h2>
            <div class="relative flex-auto overflow-y-scroll px-4">
                <div class="h-2" />
                <button
                    :class="{
                        'bg-brand-blue bg-opacity-20': !route.query.state,
                    }"
                    class="-mx-2 block rounded px-2 py-2 font-brand text-xxs text-brand-blue"
                    @click="navigateState('')"
                >
                    National
                </button>
                <button
                    v-for="state in states"
                    :id="`state_nav_${state}`"
                    :key="state"
                    :class="{
                        'bg-brand-blue bg-opacity-20': state === route.query.state,
                    }"
                    class="-mx-2 block rounded px-2 py-2 font-brand text-xxs text-brand-blue"
                    @click="navigateState(state)"
                >
                    {{ state }}
                </button>
            </div>
        </div>
        <div class="relative flex h-1/2 flex-shrink-0 flex-grow-0 flex-col rounded-b border border-t-0">
            <h2 class="-mx-2 border-b px-4 pb-2 pt-2 font-brand text-xs font-semibold uppercase opacity-50">
                Insurers
            </h2>
            <div class="relative flex-auto overflow-y-scroll px-4">
                <div class="h-2" />

                <button
                    v-for="insurer in filteredInsurers"
                    :key="insurer"
                    :class="{
                        'bg-brand-blue bg-opacity-20': insurer === route.query.insurer,
                    }"
                    class="-mx-2 block rounded px-2 py-2 text-left font-brand text-xxs text-brand-blue"
                    @click="navigateInsurer(insurer)"
                >
                    {{ insurer }}
                </button>
                <div class="h-20" />
            </div>
            <div class="absolute bottom-0 left-0 right-0 p-2">
                <input
                    v-model="filterInsurer"
                    type="text"
                    placeholder="Search Insurers"
                    class="m-0 block w-full rounded border border-slate-200 py-1 text-xs"
                />
                <button
                    v-if="filterInsurer"
                    class="absolute bottom-4 right-4 bg-slate-100 p-1 text-xxxs uppercase text-slate-500"
                    @click="filterInsurer = null"
                >
                    Clear
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const props = defineProps({
    states: {
        type: Array,
        required: true,
    },
    insurers: {
        type: Array,
        required: true,
    },
})

const router = useRouter()
const route = useRoute()

const filterInsurer = ref()
const filteredInsurers = computed(() => {
    if (filterInsurer.value) {
        return props.insurers.filter((i) => i?.toLowerCase().includes(filterInsurer.value?.toLowerCase()))
    }

    return props.insurers
})

function navigateState(state) {
    router.push({
        query: {
            state,
        },
    })
}

function navigateInsurer(insurer) {
    router.push({
        query: {
            ...route.query,
            insurer,
        },
    })
}

function scrollToState(id) {
    if (!id) return

    const state = document.getElementById(`state_nav_${id}`)
    if (!state) return

    state.scrollIntoView({ behavior: 'smooth' })
}

onMounted(async () => {
    await nextTick()
    scrollToState(route.query.state)
})
</script>
