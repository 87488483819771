<template>
    <form @submit.prevent="lookup">
        <div class="relative flex items-stretch border-gray-300 p-0 shadow-sm">
            <input
                :id="id"
                ref="inputCode"
                v-model="q"
                type="text"
                :name="name"
                class="m-0 block w-full border-slate-300 pl-24 focus:border-brand-blue focus:ring-4 focus:ring-brand-blue/20 sm:text-lg"
                :placeholder="dynamicPlaceholder"
                :readonly="readonly"
                :disabled="disabled"
                :autocomplete="autocomplete"
                :maxlength="dynamicMaxLength"
            />
            <button
                type="submit"
                class="-ml-px border border-l-0 border-slate-300 bg-slate-500 px-6 text-white outline-none focus:border-brand-blue focus:ring-4 focus:ring-brand-blue/20"
            >
                Search
            </button>
            <div class="absolute inset-y-0 left-0 flex items-center">
                <label for="country" class="sr-only">Code Type</label>
                <select
                    id="codeType"
                    v-model="codeType"
                    name="codeType23343343"
                    autocomplete="autocomplete"
                    class="h-full border-0 bg-transparent py-0 pl-3 pr-10 text-gray-500 focus:bg-brand-blue/10 focus:outline-none focus:ring-4 focus:ring-inset focus:ring-brand-blue sm:text-lg"
                >
                    <option value="EIN">EIN</option>
                    <option value="NPI">NPI</option>
                    <option value="ORG">ORG</option>
                    <option value="TAG">TAG</option>
                </select>
            </div>
        </div>
    </form>
</template>
<script setup>
import { ref, computed, watch } from 'vue'
import { useModalProviderSearch } from '@/composables/modalProviderSearch'

defineProps({
    name: {
        type: String,
        default: null,
    },
    autocomplete: {
        type: String,
        default: () => `disabled_input_for_code_${new Date().valueOf()}`,
    },
    id: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: null,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    maxlength: {
        type: Number,
        default: null,
    },
})

const codeType = ref('EIN')
const inputCode = ref()
const q = ref(null)

const { assign, modalQuery } = useModalProviderSearch()

const isCodeType = computed(() => ({
    EIN: codeType.value === 'EIN',
    NPI: codeType.value === 'NPI',
    ORG: codeType.value === 'ORG',
    TAG: codeType.value === 'TAG',
}))

const dynamicMaxLength = computed(() => 50)

const dynamicPlaceholder = computed(() => {
    if (isCodeType.value.EIN) {
        return 'Enter an EIN number to search'
    }

    if (isCodeType.value.NPI) {
        return 'Enter a NPI number to search'
    }

    if (isCodeType.value.TAG) {
        return 'Enter a Tag to search'
    }

    if (isCodeType.value.ORG) {
        return 'Enter an Organization Name to search'
    }

    return '00000'
})

if (modalQuery.value?.q) {
    q.value = modalQuery.value.q
}

if (modalQuery.value?.type) {
    codeType.value = modalQuery.value.type
}

function lookup() {
    assign({
        type: codeType.value,
        q: q.value,
    })
}

watch(
    () => codeType.value,
    () => {
        q.value = null
        inputCode.value.focus()
    }
)
</script>
