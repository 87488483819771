import { AnalyticsBrowser } from '@june-so/analytics-next'
import { reactive, unref } from 'vue'

let analytics = reactive({})

export function useAnalytics() {
    async function init() {
        if (Object.hasOwn(analytics, 'track')) return

        if (import.meta.env.VITE_ANALYTICS_ENABLED !== 'true') {
            return unref(
                Object.assign(analytics, {
                    identify: () => true,
                    track: (action, params) => console.log('analytics:track', action, params),
                    page: () => true,
                })
            )
        }

        let [june] = await AnalyticsBrowser.load({
            writeKey: import.meta.env.VITE_JUNE_WRITE_KEY,
        })

        Object.assign(analytics, june)
    }

    init()

    return {
        analytics,
    }
}
