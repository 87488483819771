<template>
    <div class="min-h-screen">
        <slot v-if="currentUser.id && !isAuthenticating" />
        <div v-else class="flex h-screen items-center justify-center">
            <div class="h-24 w-24 text-brand-purple opacity-60">
                <TheSpinner />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useCurrentUserStore } from '@/stores/currentUser'
import { onMounted } from 'vue'

import TheSpinner from '@/components/TheSpinner.vue'

const isAuthenticating = ref(true)
const router = useRouter()
const currentUser = useCurrentUserStore()

async function authenticate() {
    isAuthenticating.value = true
    currentUser
        .authenticate()
        .then(async () => {
            await currentUser.fetchAccountDetails()
        })
        .catch(async (err) => {
            console.error(err.message)

            // Forward to Confirmation Screen if user record is not confirmed
            if (err.message === 'Forbidden: Not Confirmed') {
                if (currentUser.email) {
                    await currentUser.cognito.resendConfirmationCode({
                        email: currentUser.email,
                    })

                    return router.push(
                        `/user-confirmation?${new URLSearchParams({
                            email: currentUser.email,
                        })}`
                    )
                }

                return router.push('/no-access')
            }

            if (err.message === 'Forbidden: No Access') {
                return router.push('/no-access')
            }

            router.push('/sign-in')

            isAuthenticating.value = false
        })
        .finally(() => {
            if (currentUser.isApisProduct) {
                router.push('/docs')
                return
            }
            isAuthenticating.value = false
        })
}

onMounted(async () => {
    await authenticate()
})
</script>
