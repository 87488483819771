<template>
    <div class="flex w-24 justify-center">
        <div
            :class="[
                isActiveRoute ? 'router-link-active' : '',
                'LeftNavItem relative inline-flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center overflow-visible rounded-lg',
            ]"
            @click.stop="emit('click')"
        >
            <span
                :class="{
                    'pointer-events-none -translate-x-3 opacity-0 duration-200': !isExpanded,
                    'pointer-events-auto opacity-100 duration-500': isExpanded,
                }"
                class="title absolute bottom-0 left-24 top-0 -ml-14 flex w-64 items-center pl-3 text-sm font-medium transition-all ease-out"
                >{{ item.title }}
                <span @click.stop>
                    <slot name="expander" />
                </span>
            </span>
            <span class="inline-flex h-6 w-6" aria-hidden="true">
                <slot />
            </span>
        </div>
    </div>
</template>

<script setup>
import { computed, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'

defineProps({
    item: {
        type: Object,
        required: true,
    },
    isExpanded: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['active-route-change', 'click'])

const currentRoute = useRoute()

const isActiveRoute = computed(() => Boolean(currentRoute?.query.providerSearch))

watch(
    () => isActiveRoute.value,
    () => {
        emit('active-route-change', isActiveRoute.value)
    }
)

onMounted(() => {
    if (isActiveRoute.value) {
        emit('active-route-change', isActiveRoute.value)
    }
})
</script>

<style scoped>
.LeftNavItem {
    @apply text-brand-blue text-opacity-70 hover:bg-brand-blue hover:bg-opacity-5 hover:text-opacity-90;
}
.LeftNavItem .title {
    @apply text-slate-600 hover:text-slate-800;
}

.LeftNavItem.router-link-active {
    @apply bg-slate-500 text-white text-opacity-100;
}
.LeftNavItem.router-link-active .title {
    @apply text-slate-900;
}
</style>
