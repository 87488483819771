<template>
    <button
        type="button"
        :class="{ 'rotate-180': isActive }"
        class="flex h-8 w-8 transform items-center justify-center"
        @click="emit('click')"
    >
        <span class="sr-only">Expand Section</span>
        <span class="arrow" />
    </button>
</template>

<script setup>
defineProps({
    isActive: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['click'])
</script>

<style scoped>
.arrow {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-top: 6px solid #2c64f4;
}
</style>
