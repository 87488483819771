<template>
    <div class="flex flex-col gap-4">
        <div class="grid grid-cols-3 gap-4">
            <div class="flex flex-col gap-2">
                <label for="commercial_percentage" class="block text-sm font-medium text-gray-700"
                    >Commercial mix %
                </label>
                <input
                    v-model="commercial_percentage_input"
                    name="commercial_percentage"
                    type="text"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
            </div>

            <div class="flex flex-col gap-2">
                <label for="medicare_percentage" class="block text-sm font-medium text-gray-700">Medicare mix % </label>
                <input
                    v-model="medicare_percentage_input"
                    name="medicare_percentage"
                    type="text"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
            </div>

            <div class="flex flex-col gap-2">
                <label for="Medicaid_percentage" class="block text-sm font-medium text-gray-700">Medicaid Mix % </label>
                <input
                    v-model="medicaid_percentage_input"
                    name="medicaid_percentage"
                    type="text"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4">
            <div class="flex flex-col gap-2">
                <div class="flex">
                    <label for="my_price" class="block text-sm font-medium text-gray-700">My Price</label>
                    <input v-model="locked_state" class="ml-4" type="radio" name="locked_my_price" value="my_price" />
                    <label for="locked_my_price" class="ml-1 block text-sm font-medium text-gray-700">Locked</label>
                </div>
                <input
                    v-model="my_price_input"
                    name="my_price"
                    type="text"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
            </div>
            <div class="flex flex-col gap-2">
                <div class="flex">
                    <label for="percentage_of_average" class="block text-sm font-medium text-gray-700"
                        >% of average price</label
                    >
                    <input
                        v-model="locked_state"
                        class="ml-4"
                        type="radio"
                        name="locked"
                        value="percentage_of_average"
                    />
                    <label for="locked_percentage_of_average" class="ml-1 block text-sm font-medium text-gray-700"
                        >Locked</label
                    >
                </div>
                <input
                    v-model="percentage_of_average_input"
                    name="percentage_of_average"
                    type="text"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch, triggerRef } from 'vue'

const props = defineProps({
    commercialAveragePrice: {
        type: Number,
        default: null,
    },
    commercialDefaultPercentage: {
        type: Number,
        default: null,
    },
    medicareAveragePrice: {
        type: Number,
        default: null,
    },
    medicareDefaultDercentage: {
        type: Number,
        default: null,
    },
    medicaidAveragePrice: {
        type: Number,
        default: null,
    },
    medicaidDefaultPercentage: {
        type: Number,
        default: null,
    },
})

const commercial_percentage = ref(props.commercialDefaultPercentage * 100)
const medicare_percentage = ref(props.medicareDefaultDercentage * 100)
const medicaid_percentage = ref(props.medicaidDefaultPercentage * 100)

const locked_state = ref('percentage_of_average')

function make_input_computed({ ref: primary_ref, max_value = 100 }) {
    return {
        get: () => {
            const value = primary_ref.value
            if (value == Math.floor(value)) {
                return value.toFixed(0)
            } else if (value.toFixed(1) == Math.floor(value * 10)) {
                return value.toFixed(1)
            } else {
                return value.toFixed(2)
            }
        },
        set: (value) => {
            value = parseFloat(value)
            if (isNaN(value)) {
                primary_ref.value = 0
            } else if (value < 0) {
                primary_ref.value = 0
            } else if (max_value && value > max_value) {
                primary_ref.value = 100
                triggerRef(primary_ref)
            } else {
                primary_ref.value = value
                triggerRef(primary_ref)
            }
        },
    }
}
const commercial_percentage_input = computed(make_input_computed({ ref: commercial_percentage }))
const medicare_percentage_input = computed(make_input_computed({ ref: medicare_percentage }))
const medicaid_percentage_input = computed(make_input_computed({ ref: medicaid_percentage }))

const mix_average_price = computed(() => {
    return (
        (commercial_percentage.value / 100) * props.commercialAveragePrice +
        (medicare_percentage.value / 100) * props.medicareAveragePrice +
        (medicaid_percentage.value / 100) * props.medicaidAveragePrice
    )
})

const percentage_of_average = ref(100)
const my_price = ref((percentage_of_average.value / 100) * mix_average_price.value)

const percentage_of_average_input = computed(make_input_computed({ ref: percentage_of_average, max_value: null }))
const my_price_input = computed(make_input_computed({ ref: my_price, max_value: null }))

watch(
    [percentage_of_average, my_price, mix_average_price],
    ([percentage, price, mix_average], [old_percentage, old_price, old_mix_average]) => {
        if (mix_average !== old_mix_average) {
            if (locked_state.value === 'percentage_of_average') {
                my_price.value = (percentage / 100) * mix_average
            } else {
                percentage_of_average.value = (price / mix_average) * 100
            }
        } else if (price !== old_price) {
            percentage_of_average.value = (price / mix_average) * 100
            // locked_state.value = 'my_price'
        } else if (percentage !== old_percentage) {
            my_price.value = (percentage / 100) * mix_average
            // locked_state.value = 'percentage_of_average'
        }
    }
)

function max_100({ new_value, primary_ref, other_ref }) {
    primary_ref.value = 100 - new_value - other_ref.value
    if (primary_ref.value < 0) {
        other_ref.value += primary_ref.value
        primary_ref.value = 0
    }
}

watch(
    [commercial_percentage, medicare_percentage, medicaid_percentage],
    ([commercial, medicare, medicaid], [old_commercial, old_medicare]) => {
        if (commercial !== old_commercial) {
            max_100({
                new_value: commercial,
                primary_ref: medicaid_percentage,
                other_ref: medicare_percentage,
            })
        } else if (medicare !== old_medicare) {
            max_100({
                new_value: medicare,
                primary_ref: medicaid_percentage,
                other_ref: commercial_percentage,
            })
        } else {
            max_100({
                new_value: medicaid,
                primary_ref: commercial_percentage,
                other_ref: medicare_percentage,
            })
        }
    }
)
</script>
