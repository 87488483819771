<template>
    <div class="h-screen w-full">
        <div class="flex h-full flex-col items-center justify-center sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img class="mx-auto h-12 w-auto" src="/serif_mark.svg?url" alt="Workflow" />
                <h2 class="mt-6 text-center font-brand text-3xl font-extrabold text-gray-800">
                    <slot>Check your inbox! We sent you a confirmation code.</slot>
                </h2>
            </div>
            <div v-if="error" class="sm:mx-auto sm:w-full sm:max-w-md">
                <AlertError class="mt-8 w-full border-2 border-red-200">
                    {{ error }}
                </AlertError>
            </div>
            <div class="py-4">
                <button @click="currentUser.cognito.resendConfirmationCode({ email })">
                    Resend Confirmation Code ->
                </button>
            </div>
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div class="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
                    <form class="space-y-6" action="#" method="POST" @submit.prevent="handleSubmit">
                        <div>
                            <label for="name" class="block text-sm font-medium text-gray-700">Confirmation Code</label>
                            <div class="mt-1">
                                <input
                                    id="confirmationCode"
                                    v-model="confirmationCode"
                                    name="confirmationCode"
                                    type="text"
                                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            <label for="name" class="block text-sm font-medium text-gray-700">Confirm Email</label>
                            <div class="mt-1">
                                <input
                                    id="email"
                                    v-model="email"
                                    name="email"
                                    type="text"
                                    :placeholder="$route.query.email"
                                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                class="flex w-full justify-center rounded-md border border-transparent bg-brand-purple-dark bg-opacity-80 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <TheSpinner v-if="isFetching" class="mr-3 h-5 w-5" />
                                <span v-else>Confirm Account Details</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useRouteQuery } from '@vueuse/router'

import AlertError from '/src/components/alerts/AlertError.vue'
import TheSpinner from '@/components/TheSpinner.vue'

import { useCurrentUserStore } from '/src/stores/currentUser'
import { useAnalytics } from '@/composables/analytics'

const currentUser = useCurrentUserStore()
const router = useRouter()

const emailFromQuery = useRouteQuery('email')

const { analytics } = useAnalytics()

const error = ref(null)
const isFetching = ref(false)

const email = ref(emailFromQuery.value)
const confirmationCode = ref()

async function handleSubmit() {
    isFetching.value = true

    try {
        await currentUser.confirmUser({
            email: email.value,
            confirmationCode: confirmationCode.value,
        })

        router.push(
            `/sign-in?${new URLSearchParams({
                email: email.value,
            })}`
        )
    } catch (err) {
        console.error(err)
        error.value = err.message || err

        analytics.track('User Confirmation Error', {
            error: error.value,
            email: email.value,
        })
    } finally {
        isFetching.value = false
    }
}
</script>
