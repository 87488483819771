<template>
    <div />
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useCurrentUserStore } from '@/stores/currentUser'

const currentUser = useCurrentUserStore()
const router = useRouter()

if (currentUser.defaultDashboard) {
    router.push(`/dashboards/${currentUser.defaultDashboard}`)
} else {
    router.push(`/not-found`)
}
</script>
