<template>
    <div class="flex h-full flex-col items-center justify-center sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto h-12 w-auto" src="/serif_mark.svg?url" alt="Workflow" />
            <h2 class="mt-6 text-center font-brand text-3xl font-extrabold text-gray-800">
                <slot>Sign in to your account</slot>
            </h2>
            <p class="mt-2 text-center text-sm text-gray-600">
                Or
                {{ ' ' }}
                <slot name="action">
                    <router-link
                        to="/sign-up"
                        class="font-medium text-brand-purple-dark text-opacity-80 hover:text-opacity-100"
                    >
                        create an account
                    </router-link>
                </slot>
            </p>
        </div>
        <div v-if="error" class="sm:mx-auto sm:w-full sm:max-w-md">
            <AlertError class="mt-8 w-full border-2 border-red-200">
                {{ errorCleaned }}
            </AlertError>
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
                <form class="space-y-6" action="#" method="POST" @submit.prevent="handleSubmit">
                    <div v-if="action === 'Sign Up'">
                        <label for="reg_key" class="block text-sm font-medium text-gray-700"
                            >Company Registration Key
                        </label>
                        <div class="mt-1">
                            <input
                                id="registrationKey"
                                v-model="form.registrationKey"
                                :disabled="props.regKey"
                                name="registrationKey"
                                type="text"
                                class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    <div v-if="action === 'Sign Up'">
                        <label for="name" class="block text-sm font-medium text-gray-700">Full Name</label>
                        <div class="mt-1">
                            <input
                                id="fullName"
                                v-model="form.fullName"
                                name="fullName"
                                type="text"
                                class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    <div>
                        <label for="email" class="block text-sm font-medium text-gray-700"> Email Address </label>
                        <div class="mt-1">
                            <input
                                id="email"
                                v-model="form.email"
                                name="email"
                                type="email"
                                autocomplete="email"
                                class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    <div>
                        <label for="password" class="block text-sm font-medium text-gray-700"> Password </label>
                        <div class="mt-1">
                            <input
                                id="password"
                                v-model="form.password"
                                name="password"
                                type="password"
                                autocomplete="current-password"
                                class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    <div v-if="action === 'Sign In'" class="flex items-center justify-end">
                        <div class="text-sm">
                            <router-link
                                to="/forgot-password"
                                class="font-medium text-brand-purple-dark text-opacity-80 hover:text-opacity-100"
                            >
                                Forgot your password?
                            </router-link>
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            class="flex w-full justify-center rounded-md border border-transparent bg-brand-purple-dark bg-opacity-80 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            <TheSpinner v-if="isFetching" class="mr-3 h-5 w-5" />
                            <span v-else>{{ action }}</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, computed } from 'vue'

import AlertError from '/src/components/alerts/AlertError.vue'
import TheSpinner from '@/components/TheSpinner.vue'

const props = defineProps({
    action: {
        type: String,
        default: 'Sign In',
    },
    isFetching: {
        type: Boolean,
        default: false,
    },
    email: {
        type: String,
        default: null,
    },
    error: {
        type: String,
        default: null,
    },
    regKey: {
        type: String,
        default: null,
    },
})

const emit = defineEmits(['submit'])

const form = reactive({
    email: props.email,
    password: null,
    registrationKey: props.regKey,
    fullName: null,
})

const errorCleaned = computed(() => {
    if (!props.error) return null

    try {
        const errObject = JSON.parse(props.error)

        if (errObject.message) return errObject.message

        return errObject
    } catch {
        return props.error
    }
})

function handleSubmit() {
    // Run validations someday
    emit('submit', form)
}
</script>
