<template>
    <button
        :class="{ 'bg-slate-800 text-white': isCurrent }"
        class="flex items-center px-2 py-1.5"
        @click="emit('click')"
    >
        <span v-if="includeArrow" class="opacity-40">-></span>
        <span class="ml-1 opacity-60">{{ q.type }}</span
        ><span class="ml-0.5 font-medium">{{ q.q }}</span>
    </button>
</template>

<script setup>
defineProps({
    includeArrow: {
        type: Boolean,
        default: true,
    },
    isCurrent: {
        type: Boolean,
        default: false,
    },
    q: {
        type: Object,
        required: true,
    },
})

const emit = defineEmits(['click'])
</script>
