<template>
    <div>
        <button
            class="flex items-center space-x-3"
            :aria-expanded="isOpen"
            :aria-controls="`collapse${_uid}`"
            @click="toggleAccordion()"
        >
            <slot name="title" />

            <span class="inline-flex h-6 w-6 items-center justify-center bg-slate-100 text-brand-blue/70">
                <svg
                    class="h-auto w-3 transform transition-all duration-200"
                    :class="{
                        'rotate-180': isOpen,
                        'rotate-0': !isOpen,
                    }"
                    fill="none"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 10"
                    aria-hidden="true"
                >
                    <path d="M15 1.2l-7 7-7-7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </span>
        </button>

        <div v-show="isOpen" :id="`collapse${_uid}`">
            <slot name="content" />
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue'

const isOpen = ref(false)

const _uid = ref(null)

const emit = defineEmits('opened')

const toggleAccordion = function () {
    isOpen.value = !isOpen.value

    if (isOpen.value) {
        emit('opened')
    }
}
</script>
