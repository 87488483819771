<template>
    <button
        type="button"
        :class="{
            'text-brand-blue/70 hover:bg-slate-100 hover:text-brand-blue/100': !copied,
            'text-emerald-700': copied,
        }"
        class="flex items-center justify-center text-xs uppercase"
        @click.stop.prevent="handleClick"
    >
        <CheckIcon v-if="copied" class="h-3 w-3" />
        <ClipboardIcon v-else class="h-3 w-3" />
        <slot />
    </button>
</template>

<script setup>
import { ClipboardIcon, CheckIcon } from '@heroicons/vue/24/outline'
import { useClipboard } from '@vueuse/core'

const props = defineProps({
    data: {
        type: String,
        default: null,
    },
})

const { copy, copied } = useClipboard()

function handleClick() {
    try {
        copy(props.data)
    } catch (e) {
        console.warn(e.message)
    }
}
</script>
