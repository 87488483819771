<template>
    <div class="absolute inset-0 flex items-stretch bg-white">
        <div class="flex w-1/3 flex-col border-r">
            <div class="flex-auto overflow-y-auto px-10 pt-4">
                <div v-if="relationships.name" class="pb-4 pt-4">
                    <p class="mt-0.5 text-lg font-semibold text-slate-600">
                        {{ relationships.name }}
                    </p>

                    <p class="mt-0.5 text-lg text-slate-600">
                        {{ relationships.classification }}, {{ relationships.state }}
                    </p>
                </div>

                <div v-if="currentQuery.type === 'EIN'">
                    <h3 class="mt-6 flex items-center text-sm font-semibold uppercase text-slate-500">
                        <span class="inline-flex items-center font-bold">
                            <TagIcon class="mr-0 h-4 w-4" />
                        </span>
                        <span class="ml-1">Tags</span>

                        <button
                            class="ml-3 flex h-5 w-5 items-center justify-center bg-brand-blue/10 text-brand-blue/70"
                            @click="isTagCreateActive = !isTagCreateActive"
                        >
                            <XMarkIcon v-if="isTagCreateActive" class="h-4 w-4" />
                            <PlusIcon v-else class="h-4 w-4" />
                        </button>
                        <button
                            class="ml-1.5 flex h-5 w-5 items-center justify-center bg-brand-blue/10 text-brand-blue/70"
                            @click="isTagEditActive = !isTagEditActive"
                        >
                            <XMarkIcon v-if="isTagEditActive" class="h-4 w-4" />
                            <EllipsisHorizontalIcon v-else class="h-4 w-4" />
                        </button>
                    </h3>
                    <ProviderSearchTagCreate v-if="isTagCreateActive" class="mt-3" @done="emit('new-tags', $event)" />

                    <div class="mt-3 bg-opacity-70">
                        <ProviderSearchTagRecord
                            v-for="tag in relationships.tags"
                            :key="tag"
                            :record="tag"
                            :edit-mode="isTagEditActive"
                            class="mr-1.5 mt-1.5"
                            @done="emit('new-tags', $event)"
                        />
                    </div>
                </div>

                <div v-if="relationships.eins?.length">
                    <h3 class="mt-6 flex items-center text-sm font-semibold uppercase text-slate-500">
                        <span class="inline-flex items-center font-bold">
                            <IdentificationIcon class="mr-1 h-4 w-4" />
                        </span>
                        <span class="ml-2">EINs</span>
                        <ButtonCopyTiny :data="relationships.eins.map((i) => i)" class="ml-4" />
                    </h3>
                    <div class="mt-3 bg-slate-50 bg-opacity-70">
                        <ProviderSearchEinRecord v-for="record in relationships.eins" :key="record" :record="record" />
                    </div>
                </div>

                <div v-if="relationships.preferredOrgName?.length">
                    <h3 class="mt-6 flex items-center text-sm font-semibold uppercase text-slate-500">
                        <span class="inline-flex items-center font-bold">
                            <UserGroupIcon class="mr-0 h-4 w-4" />
                        </span>
                        <span class="ml-2">Preferred Organization Name</span>
                    </h3>
                    <div class="mt-3 max-h-[30rem] overflow-y-auto border-b-2 border-t-2 bg-slate-50 bg-opacity-70">
                        <ProviderSearchResultActions :q="{ q: relationships.preferredOrgName, type: 'ORG' }">
                            <div class="cursor-pointer border-t border-slate-200 px-5 py-6">
                                <p class="text-sm text-slate-600">
                                    {{ relationships.preferredOrgName }}
                                </p>
                            </div>
                        </ProviderSearchResultActions>
                    </div>
                </div>

                <div v-if="relationships.orgNames?.length">
                    <h3 class="mt-6 flex items-center text-sm font-semibold uppercase text-slate-500">
                        <span class="inline-flex items-center font-bold">
                            <UserGroupIcon class="mr-0 h-4 w-4" />
                        </span>
                        <span class="ml-2">Organization Names</span>
                    </h3>
                    <div class="mt-3 max-h-[30rem] overflow-y-auto border-b-2 border-t-2 bg-slate-50 bg-opacity-70">
                        <ProviderSearchOrgNameRecord
                            v-for="record in relationships.orgNames"
                            :key="record"
                            :record="record"
                        />
                    </div>
                </div>

                <div v-if="relationships.typeTwoNPIs?.length">
                    <h3 class="mt-6 flex items-center text-sm font-semibold uppercase text-slate-500">
                        <span class="inline-flex items-center font-bold text-emerald-600">
                            <BuildingOffice2Icon class="mr-1 h-4 w-4" /> Type 2</span
                        >
                        <span class="ml-2">NPIs</span>
                        <select
                            v-if="typeTwoPages?.length > 1"
                            id="typeTwoPageSelector"
                            v-model="selectedTypeTwoPage"
                            name="typeTwoPageList"
                            class="text-x ml-3 appearance-none border-0 bg-slate-100 py-0 pl-3 text-xs"
                            @change="emit('set-active-type-two-page', selectedTypeTwoPage)"
                        >
                            <option v-for="page in typeTwoPages" :key="page" :value="page">
                                {{ page }}
                            </option>
                        </select>
                        <slot name="npi-type-two-actions" />
                    </h3>
                    <div class="mt-3 max-h-[30rem] overflow-y-auto border-b-2 border-t-2 bg-emerald-50 bg-opacity-70">
                        <ProviderSearchNPIRecord
                            v-for="record in relationships.typeTwoNPIs"
                            :key="record.npi"
                            :record="record"
                        />
                    </div>
                </div>

                <div v-if="relationships">
                    <AccordionExpander
                        v-if="relationships.typeOneNPIs?.length"
                        class="mb-4 mt-6"
                        @opened="relationships.eins?.length ? emit('load-npi-type-ones', 1) : emit('load-type-ones', 1)"
                    >
                        <template #title>
                            <h3 class="flex items-center text-sm font-semibold uppercase text-slate-500">
                                <span class="inline-flex items-center font-bold text-cyan-600">
                                    <UserIcon class="mr-1 h-4 w-4" /> Type 1</span
                                >
                                <span class="ml-2">NPIs</span>
                            </h3>
                            <div v-if="relationships.typeOneNPIs?.length" class="flex gap-x-2" @click.stop>
                                <!-- <select
                                    id="typeOnePageSelector"
                                    v-model="selectedTypeOnePage"
                                    name="typeOnePageList"
                                    class="text-x ml-3 appearance-none border-0 bg-slate-100 py-0 pl-3 text-xs"
                                    @change="emit('set-active-type-one-page', selectedTypeOnePage)"
                                >
                                    <option v-for="page in typeOnePages" :key="page" :value="page">
                                        {{ page }}
                                    </option>
                                </select> -->

                                <slot name="npi-type-one-actions" />
                            </div>
                        </template>
                        <template #content>
                            <div v-if="isLoadingTypeOneNPIs">
                                <TheSpinner class="mx-auto mt-24 h-24 w-24 opacity-60" />
                            </div>
                            <div v-else>
                                <div
                                    class="mt-3 max-h-[30rem] overflow-y-auto border-b-2 border-t-2 bg-cyan-50 bg-opacity-70"
                                >
                                    <ProviderSearchNPIRecord
                                        v-for="record in relationships.typeOneNPIs"
                                        :key="record.npi"
                                        :record="record"
                                    />
                                </div>
                            </div>
                        </template>
                    </AccordionExpander>
                </div>

                <div class="h-6" />
            </div>
        </div>
        <div class="flex flex-auto flex-col">
            <ProviderSearchTabs
                :counts="{
                    payors: relationships.payors?.length,
                }"
            />
            <div class="flex-auto overflow-y-auto">
                <ProviderSearchResultsPayors v-if="relationships.payors?.length" :payors="relationships.payors" />
                <div v-else class="mt-16 flex items-center justify-center">
                    <FaceFrownIcon class="h-24 w-24 text-slate-300" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import {
    UserIcon,
    BuildingOffice2Icon,
    TagIcon,
    UserGroupIcon,
    IdentificationIcon,
    PlusIcon,
    XMarkIcon,
    EllipsisHorizontalIcon,
    FaceFrownIcon,
} from '@heroicons/vue/20/solid'
import TheSpinner from '@/components/TheSpinner.vue'

import AccordionExpander from '@/components/AccordionExpander.vue'
import ProviderSearchTabs from './ProviderSearchTabs.vue'
import ProviderSearchResultsPayors from './ProviderSearchResultsPayors.vue'
import ProviderSearchOrgNameRecord from './ProviderSearchOrgNameRecord.vue'
import ProviderSearchNPIRecord from './ProviderSearchNPIRecord.vue'
import ProviderSearchEinRecord from './ProviderSearchEinRecord.vue'
import ProviderSearchTagCreate from './ProviderSearchTagCreate.vue'
import ProviderSearchTagRecord from './ProviderSearchTagRecord.vue'
import ButtonCopyTiny from '@/components/buttons/ButtonCopyTiny.vue'
import ProviderSearchResultActions from './ProviderSearchResultActions.vue'

const props = defineProps({
    currentQuery: {
        type: [Object, Boolean],
        default: null,
    },
    relationships: {
        type: Object,
        required: true,
    },
    typeOnePages: {
        type: Array,
        default: null,
    },
    activeTypeOnePage: {
        type: Number,
        default: 0,
    },
    typeTwoPages: {
        type: Array,
        default: null,
    },
    activeTypeTwoPage: {
        type: Number,
        default: 0,
    },
})

// const selectedTypeOnePage = ref(props.activeTypeOnePage)

const selectedTypeTwoPage = ref(props.activeTypeTwoPage)

const emit = defineEmits([
    'new-tags',
    'load-npi-type-ones',
    'load-type-ones',
    'set-active-type-one-page',
    'set-active-type-two-page',
])

const isTagCreateActive = ref(false)
const isTagEditActive = ref(false)

const isLoadingTypeOneNPIs = ref(false)
</script>
