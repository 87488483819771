<template>
    <div
        class="footer fixed bottom-0 left-0 z-50 h-10 w-full items-center border-t border-opacity-30 bg-white px-5 md:flex"
    >
        <p class="footer-text mr-8 mt-1.5 text-left text-xxs text-gray-500 md:mt-0">
            © 2024
            <a
                class="font-medium text-brand-purple-dark text-opacity-80 hover:text-opacity-100"
                href="https://www.serifhealth.com"
                >Serif Health</a
            >.
            <a
                class="ml-4 inline-block font-medium text-brand-purple-dark text-opacity-80 hover:text-opacity-100"
                href="https://www.serifhealth.com/page-terms"
                >Terms of Use</a
            >
            <a
                class="ml-4 inline-block font-medium text-brand-purple-dark text-opacity-80 hover:text-opacity-100"
                href="https://www.serifhealth.com/page-privacy"
                >Privacy Policy</a
            >
        </p>
        <div class="flex-auto" />
        <p class="footer-text mt-0 text-left text-xxs text-gray-500 md:mt-0">
            CPT&trade; © 2024 American Medical Association. All rights reserved.
        </p>
    </div>
</template>
