import { ref, watch, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useRouteQuery } from '@vueuse/router'

const onSuccessCallback = ref(null)

export function useModal() {
    const route = useRoute()

    const modalQuery = useRouteQuery('modal')

    const modals = ref([])

    const current = computed(() => modals.value.filter((s) => s.isCurrent))

    const isModalOpen = computed(() => current.value.length)

    function findModals() {
        modals.value = []

        route.matched.forEach((r) => {
            if (!r.components) return

            const modalComponents = Object.keys(r.components).filter((c) => c.startsWith('modal'))
            modalComponents.forEach((key) => {
                const isExisting = modals.value.find((m) => m.name === key)
                if (isExisting) {
                    isExisting.isCurrent = key === route.query.modal?.name
                } else {
                    modals.value.push({
                        name: key,
                        isCurrent: key === route.query.modal?.name,
                    })
                }
            })
        })
    }

    onMounted(() => findModals())

    watch(
        () => route.name,
        () => findModals()
    )

    watch(
        () => route.query.modal,
        (modal) => {
            modals.value.forEach((s) => {
                s.isCurrent = s.name === modal?.name
            })
        }
    )

    function launch(modalName, data = {}, passedOnSuccess = null) {
        modalQuery.value = {
            name: modalName,
            ...data,
        }

        onSuccessCallback.value = passedOnSuccess
    }

    function dismiss() {
        modalQuery.value = null
        onSuccess.value = null
    }

    function onSuccess() {
        if (onSuccessCallback.value) {
            onSuccessCallback.value()
        }
    }

    return {
        current,
        launch,
        dismiss,
        onSuccess,
        isModalOpen,
        modalQuery,
    }
}
