<template>
    <div class="flex w-24 justify-center">
        <div
            :class="[
                isActive ? 'router-link-active' : '',
                'LeftNavItem Button relative inline-flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center overflow-visible rounded-lg',
            ]"
            @click.stop="emit('click')"
        >
            <span
                :class="{
                    'pointer-events-none -translate-x-3 opacity-0 duration-200': !isExpanded,
                    'pointer-events-auto opacity-100 duration-500': isExpanded,
                }"
                class="title absolute bottom-0 left-24 top-0 -ml-14 flex w-64 items-center pl-3 text-sm font-medium transition-all ease-out"
                >{{ item.title }}
                <span @click.stop>
                    <slot name="expander" />
                </span>
            </span>
            <span class="inline-flex h-6 w-6" aria-hidden="true">
                <slot />
            </span>
        </div>
    </div>
</template>

<script setup>
defineProps({
    item: {
        type: Object,
        required: true,
    },
    isExpanded: {
        type: Boolean,
        default: false,
    },
    isActive: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['click'])
</script>

<style scoped>
.LeftNavItem.Button {
    @apply bg-brand-blue/70 text-white/90 text-opacity-70 hover:bg-brand-blue/100 hover:text-opacity-90;
}
.LeftNavItem.Button .title {
    @apply text-slate-600 hover:text-slate-800;
}

.LeftNavItem.Button.router-link-active {
    @apply bg-slate-500 text-white text-opacity-100;
}
.LeftNavItem.Button.router-link-active .title {
    @apply text-slate-900;
}
</style>
