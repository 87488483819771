<template>
    <GutteredBlockScroller>
        <MarketShare
            :data="marketShare.calcualtedByState(route.query.state)"
            :states="marketShare.availableStates"
            :insurers="marketShare.availableInsurers"
        />
    </GutteredBlockScroller>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'

import GutteredBlockScroller from '@/components/page/GutteredBlockScroller.vue'
import MarketShare from '@/components/market-share/MarketShare.vue'

import { useMarketShareStore } from '@/stores/marketShare'

const route = useRoute()
const marketShare = useMarketShareStore()

onMounted(() => marketShare.fetch())
</script>
