export function useNumberFormatter() {
    const format = (number) => new Intl.NumberFormat().format(number)

    const addCommas = (number) => {
        if (!number) return number

        return Number(number).toLocaleString()
    }

    const formatPercent = (number) => `${number}%`

    const formatPercentConverted = (number) =>
        number?.toLocaleString('en-US', {
            style: 'percent',
            maximumFractionDigits: 1,
        })

    const formatPercentBase = (number) =>
        number?.toLocaleString('en-US', {
            style: 'percent',
            maximumFractionDigits: 0,
        })
    const formatCurrency = (number) =>
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        }).format(number)

    const formatShorthand = (item) => {
        if (typeof item === 'number') {
            if (item === 0) {
                return item
            } else if (item > 1000000000) {
                item = (item / 1000000000).toFixed(3) + ' B'
            } else if (item > 1000000) {
                item = (item / 1000000).toFixed(3) + ' M'
            } else if (item > 1000) {
                item = (item / 1000).toFixed(1) + ' K'
            }
        }

        return item
    }

    return {
        format,
        formatPercent,
        formatPercentConverted,
        formatCurrency,
        formatPercentBase,
        formatShorthand,
        addCommas,
    }
}
