<template>
    <div class="h-screen w-full" />
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useCurrentUserStore } from '@/stores/currentUser'

const currentUser = useCurrentUserStore()
const router = useRouter()

async function handleLogout() {
    currentUser
        .signOut()
        .catch((err) => {
            console.error(err)
        })
        .finally(() => {
            router.push('/sign-in')
        })
}

handleLogout()
</script>
