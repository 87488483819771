<template>
    <PageLayout>
        <PageHeading><span class="opacity-60">Market Share:</span> {{ route.query.state || 'National' }}</PageHeading>
        <router-view />
    </PageLayout>
</template>

<script setup>
import { useRoute } from 'vue-router'

import PageLayout from '@/components/page/PageLayout.vue'
import PageHeading from '@/components/page/PageHeading.vue'

const route = useRoute()
</script>
