<template>
    <p
        :class="{
            'pointer-events-none opacity-40': isInSearch({
                q: record,
                type: 'TAG',
            }),
        }"
        class="whitespace-no-wrap group relative inline-block inline-flex max-w-[325px] cursor-pointer items-center overflow-visible bg-slate-200 px-2 py-0.5 text-sm font-medium text-slate-600 hover:bg-slate-300 hover:text-slate-800"
        @click="
            assignChild({
                q: record,
                type: 'TAG',
            })
        "
    >
        <span class="truncate">{{ record }}</span>
        <button
            v-if="editMode"
            class="z-10 -my-2 ml-3 inline-flex w-8 items-center justify-center bg-rose-100 text-rose-500 hover:bg-rose-100 hover:text-rose-900"
            @click="deleteTag"
        >
            <ArrowPathIcon v-if="isFetching" class="h-4 w-4 animate-spin" />
            <XCircleIcon v-else class="h-4 w-4" />
        </button>
    </p>
</template>

<script setup>
import { ref, nextTick } from 'vue'
import { ArrowPathIcon, XCircleIcon } from '@heroicons/vue/20/solid'

import { useModalProviderSearch } from '@/composables/modalProviderSearch'
import { useAPI } from '@/composables/api'

const props = defineProps({
    record: {
        type: String,
        required: true,
    },
    editMode: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['done'])

const { relationshipsAPI } = useAPI()
const { currentQuery, assignChild, isInSearch } = useModalProviderSearch()
const isFetching = ref(false)

const deleteTag = async function () {
    try {
        isFetching.value = true

        await nextTick()

        await relationshipsAPI(`/tags/ein`, {
            method: 'DELETE',
            body: {
                tag: props.record,
                ein: currentQuery.value.q,
            },
        })

        const tags = await reloadTags()

        emit('done', tags)
    } catch (error) {
        console.error(error.message)
    } finally {
        isFetching.value = false
    }
}

const reloadTags = async function () {
    return await relationshipsAPI(`/tags/ein/${currentQuery.value.q}`, {
        method: 'GET',
    })
}
</script>
