import { defineStore } from 'pinia'

import { useAPI } from '@/composables/api'

const sumArray = (sum, x) => sum + x
const convertPercent = (val) => (val * 100).toFixed(2)
const sumProperty = (property) => {
    if (property.length) {
        return property.reduce(sumArray)
    }

    return 0
}

export const useMarketShareStore = defineStore('marketShare', {
    state: () => {
        return {
            data: [],
        }
    },
    getters: {
        availableStates: (state) => [...new Set(state.data.map((r) => r.state))],
        availableInsurers: (state) => [...new Set(state.data.map((r) => r.insurer))],
        byState: (state) => {
            return (selectedState) => {
                if (selectedState) {
                    return state.data.filter((r) => r.state === selectedState)
                }

                return state.data
            }
        },
        calcualtedByState() {
            return (selectedState) => {
                const compiledData = {
                    state: 'National',
                    coveredLives: {
                        total: 0,
                        commercialRisk: {
                            total: 0,
                            share: 0,
                        },
                        asoEnrollment: {
                            total: 0,
                            share: 0,
                        },
                    },
                    insurers: [],
                }

                if (selectedState) {
                    compiledData.state = selectedState
                }

                // Sum of Commercial Risk
                compiledData.coveredLives.commercialRisk.total = sumProperty(
                    this.byState(selectedState).map((r) => r.totalCommercialRisk)
                )

                // Sum of ASO Enrollement
                compiledData.coveredLives.asoEnrollment.total = sumProperty(
                    this.byState(selectedState).map((r) => r.asoEnrollment)
                )

                // Sum of Covered Lives
                compiledData.coveredLives.total =
                    compiledData.coveredLives.commercialRisk.total + compiledData.coveredLives.asoEnrollment.total

                // Share of Commercial Risk
                compiledData.coveredLives.commercialRisk.share = convertPercent(
                    compiledData.coveredLives.commercialRisk.total / compiledData.coveredLives.total
                )

                // Share of Aso Enrollment
                compiledData.coveredLives.asoEnrollment.share = convertPercent(
                    compiledData.coveredLives.asoEnrollment.total / compiledData.coveredLives.total
                )

                // Calculate share PER Insurer
                this.availableInsurers.forEach((i) => {
                    const insurer = {
                        name: i,
                        coveredLives: {
                            total: 0,
                            share: 0,
                            commercialRisk: {
                                total: 0,
                                share: 0,
                            },
                            asoEnrollment: {
                                total: 0,
                                share: 0,
                            },
                        },
                        topStates: [],
                    }

                    const insurerData = this.byState(selectedState).filter((r) => r.insurer === i)

                    // Sum Commercial Risk
                    insurer.coveredLives.commercialRisk.total = sumProperty(
                        insurerData.map((r) => r.totalCommercialRisk)
                    )

                    // Sum ASO enrollment
                    insurer.coveredLives.asoEnrollment.total = sumProperty(insurerData.map((r) => r.asoEnrollment))

                    // Sum Covereed Lives
                    insurer.coveredLives.total =
                        insurer.coveredLives.commercialRisk.total + insurer.coveredLives.asoEnrollment.total

                    // Share of Commericial Risk
                    insurer.coveredLives.commercialRisk.share = convertPercent(
                        insurer.coveredLives.commercialRisk.total / insurer.coveredLives.total
                    )

                    // Share of ASO enrollment
                    insurer.coveredLives.asoEnrollment.share = convertPercent(
                        insurer.coveredLives.asoEnrollment.total / insurer.coveredLives.total
                    )

                    // Share of Insurer
                    insurer.coveredLives.share = convertPercent(
                        insurer.coveredLives.total / compiledData.coveredLives.total
                    )

                    // Find Top States for National view
                    if (!selectedState) {
                        insurerData.sort(
                            (a, b) =>
                                b.totalCommercialRisk + b.asoEnrollment - (a.totalCommercialRisk + a.asoEnrollment)
                        )
                        insurer.topStates = insurerData.slice(0, 3)
                        insurer.topStates = insurer.topStates.map((r) => r.state)
                    }

                    // Don't add co's with no coverage
                    if (insurer.coveredLives.total > 0) {
                        compiledData.insurers.push(insurer)
                    }
                })

                compiledData.insurers.sort((a, b) => {
                    return b.coveredLives.total - a.coveredLives.total
                })

                return compiledData
            }
        },
    },
    actions: {
        async fetch() {
            const { lookupAPI } = useAPI()

            const data = await lookupAPI(`/market-share/state-market-breakdowns`)

            // Remove Foreign entries
            this.data = data.filter((r) => r.state !== 'Foreign')
        },
    },
})
