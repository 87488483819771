<template>
    <div :class="{ 'opacity-40': isInSearch(q) }" class="relative">
        <slot />
        <button
            v-if="!isInSearch(q)"
            class="absolute right-1 top-1 flex items-center border bg-white px-2 py-1 text-xs"
            @click="assignChild(q)"
        >
            <DocumentMagnifyingGlassIcon class="mr-1 h-3 w-3" /> Search
        </button>
    </div>
</template>

<script setup>
import { useModalProviderSearch } from '@/composables/modalProviderSearch'
import { DocumentMagnifyingGlassIcon } from '@heroicons/vue/24/outline'

defineProps({
    q: {
        type: Object,
        required: true,
    },
})

const { assignChild, isInSearch } = useModalProviderSearch()
</script>
