import { computed } from 'vue'
import { useRouteQuery } from '@vueuse/router'

export function useModalProviderSearch() {
    const modalQuery = useRouteQuery('ps')
    const modalQuerySearches = useRouteQuery('psq', [], {
        transform: (param) => (Array.isArray(param) ? param.filter((p) => p) : [param].filter((p) => p)),
    })

    const isModalOpen = computed(() => Boolean(modalQuery.value))

    const currentQuery = computed(() => {
        if (modalQuery.value?.child === 'initial') return modalQuery.value

        const currentQ = modalQuerySearches.value.find((q) => q.id === modalQuery.value.child)

        if (!currentQ) return null

        return currentQ
    })

    function launch() {
        modalQuery.value = {
            q: null,
            type: null,
            child: null,
        }
    }

    function dismiss() {
        modalQuery.value = null
        modalQuerySearches.value = null
    }

    function assign(query) {
        modalQuery.value = {
            ...modalQuery.value,
            ...query,
            child: 'initial',
            t: new Date().valueOf(),
        }
        modalQuerySearches.value = null
    }

    function assignInitial(to = 'initial') {
        modalQuery.value = {
            ...modalQuery.value,
            child: to,
        }
    }

    function assignChild(query) {
        const id = `q_${query.q}_${query.type}`
        modalQuerySearches.value = [...modalQuerySearches.value, { ...query, id, from: modalQuery.value.child }]
        modalQuery.value = {
            ...modalQuery.value,
            child: id,
        }
    }

    function isInSearch(query) {
        return modalQuerySearches.value?.some((q) => q?.q === query?.q && q?.type === query?.type)
    }

    return {
        launch,
        dismiss,
        assign,
        assignInitial,
        assignChild,
        isModalOpen,
        modalQuery,
        modalQuerySearches,
        currentQuery,
        isInSearch,
    }
}
