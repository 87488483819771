<template>
    <div class="w-full pl-36 pr-12 md:flex md:h-20 md:items-center">
        <div class="flex w-full items-center">
            <h2 class="flex items-center text-left font-brand text-2xl leading-none text-slate-600">
                <slot />
            </h2>
            <slot name="actions" />
        </div>
    </div>
</template>
