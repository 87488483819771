<template>
    <div>
        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select
                id="tabs"
                name="tabs"
                class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            >
                <option v-for="tab in tabs" :key="tab.name" :selected="tab.current.value">
                    {{ tab.name }}
                </option>
            </select>
        </div>
        <div class="hidden sm:block">
            <div class="border-b border-gray-200 px-10">
                <nav class="-mb-px flex space-x-8 pt-1" aria-label="Tabs">
                    <button v-for="tab in tabs" :key="tab.name" class="block" @click="assign(tab.to)">
                        <span
                            :class="[
                                tab.current.value
                                    ? 'border-slate-300 text-slate-700'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'group inline-flex items-center border-b-4 px-1 py-4 text-sm font-medium',
                            ]"
                        >
                            <component
                                :is="tab.icon"
                                :class="[
                                    tab.current.value ? 'text-slate-700' : 'text-gray-400 group-hover:text-gray-500',
                                    '-ml-0.5 mr-2 h-5 w-5',
                                ]"
                                aria-hidden="true"
                            />
                            <span>{{ tab.name }}</span>
                            <span
                                v-if="tab.count"
                                class="ml-2 rounded bg-slate-50 px-2 py-0.5 text-xs font-semibold text-slate-600"
                                >{{ tab.count }}</span
                            >
                        </span>
                    </button>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { ShieldCheckIcon } from '@heroicons/vue/20/solid'

import { useModalProviderSearch } from '@/composables/modalProviderSearch'

const props = defineProps({
    counts: {
        type: Object,
        default: () => {},
    },
})

const tabs = [
    {
        name: 'Found Payors',
        to: { tab: 'found-payors' },
        icon: ShieldCheckIcon,
        current: computed(() => modalQuery.value?.tab === 'found-payors'),
        count: props.counts.payors,
        modes: ['claims', 'comparision'],
    },
    // {
    //     name: 'Provider Details',
    //     to: { tab: 'provider-details' },
    //     icon: DocumentTextIcon,
    //     current: computed(() => modalQuery.value?.tab === 'provider-details'),
    //     modes: ['claims'],
    // },
]

const { assign, modalQuery } = useModalProviderSearch()
</script>
