export function useServerError() {
    function getMessage(err) {
        try {
            const errObject = JSON.parse(err.message)

            if (errObject.message) return errObject.message

            return errObject
        } catch {
            return err.message || err
        }
    }

    return {
        getMessage,
    }
}
