<template>
    <div class="overflow-hidden border border-gray-200">
        <ul class="divide-y divide-gray-100 bg-white">
            <li
                class="grid grid-cols-16 items-center bg-slate-100 px-6 py-2 text-xs font-medium uppercase tracking-wider text-brand-purple"
            >
                <slot name="headers" />
            </li>
            <slot />
        </ul>
    </div>
</template>
