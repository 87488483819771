<template>
    <div class="h-screen w-full">
        <div v-if="!resetDone" class="flex h-full flex-col items-center justify-center sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img class="mx-auto h-12 w-auto" src="/serif_mark.svg?url" alt="Workflow" />
                <h2 class="mt-6 text-center font-brand text-3xl font-extrabold text-gray-800">Forgot Your Password</h2>
                <p class="mt-2 text-center text-sm text-gray-600">
                    Or
                    {{ ' ' }}
                    <router-link
                        to="/sign-in"
                        class="font-medium text-brand-purple-dark text-opacity-80 hover:text-opacity-100"
                    >
                        return to signin
                    </router-link>
                </p>
            </div>
            <div v-if="error" class="sm:mx-auto sm:w-full sm:max-w-md">
                <AlertError class="mt-8 w-full border-2 border-red-200">
                    {{ error }}
                </AlertError>
            </div>
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div class="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
                    <form class="space-y-6" action="#" method="POST" @submit.prevent="handleSubmit">
                        <div>
                            <label for="email" class="block text-sm font-medium text-gray-700"> Email address </label>
                            <div class="mt-1">
                                <input
                                    id="email"
                                    v-model="form.email"
                                    name="email"
                                    type="email"
                                    autocomplete="email"
                                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                class="flex w-full justify-center rounded-md border border-transparent bg-brand-purple-dark bg-opacity-80 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <TheSpinner v-if="isFetching" class="mr-3 h-5 w-5" />
                                <span v-else>Forgot Password</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div v-else class="flex h-full flex-col items-center justify-center sm:px-6 lg:px-8">
            <img class="mx-auto mb-4 h-12 w-auto" src="/serif_mark.svg?url" alt="Workflow" />
            <div class="sm:mx-auto">
                <AlertSuccess>
                    Reset password link sent to your inbox. Check your email, and click on the link there to continue.
                </AlertSuccess>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue'
import { useCurrentUserStore } from '/src/stores/currentUser'

import AlertError from '/src/components/alerts/AlertError.vue'
import TheSpinner from '@/components/TheSpinner.vue'
import AlertSuccess from '/src/components/alerts/AlertSuccess.vue'

const error = ref(null)
const isFetching = ref(false)
const currentUser = useCurrentUserStore()
const resetDone = ref(false)

const form = reactive({
    email: null,
})

async function handleSubmit() {
    isFetching.value = true
    currentUser
        .forgotPassword(form)
        // eslint-disable-next-line no-unused-vars
        .then((result) => {
            resetDone.value = true
        })
        .catch((err) => {
            error.value = err.message || err
        })
        .finally(() => {
            isFetching.value = false
        })
}

onMounted(() => {})
</script>
