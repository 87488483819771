<template>
    <div class="h-screen w-full">
        <FormSignIn
            :error="error"
            :is-fetching="isFetching"
            :action="'Sign Up'"
            :reg-key="regKey"
            @submit="handleSubmit"
        >
            Create your account
            <template #action>
                <router-link
                    to="/sign-in"
                    class="font-medium text-brand-purple-dark text-opacity-80 hover:text-opacity-100"
                >
                    sign in to your account
                </router-link>
            </template>
        </FormSignIn>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useRouteQuery } from '@vueuse/router'
import FormSignIn from '/src/components/forms/FormSignIn.vue'
import { useCurrentUserStore } from '/src/stores/currentUser'

const error = ref(null)
const isFetching = ref(false)
const currentUser = useCurrentUserStore()
const router = useRouter()
const regKey = useRouteQuery('regKey')

async function handleSubmit({ email, password, registrationKey, fullName }) {
    isFetching.value = true

    try {
        const createdUser = await currentUser.signUp({
            email,
            password,
            registrationKey,
            fullName,
        })

        if (!createdUser) throw new Error('An error occured')

        router.replace(
            `/user-confirmation?${new URLSearchParams({
                email,
            })}`
        )
    } catch (err) {
        console.warn(err.message)
        error.value = err.message || err
    } finally {
        isFetching.value = false
    }
}
</script>
