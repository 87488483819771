<template>
    <div
        v-if="modalQuerySearches.length"
        :class="{ 'pointer-events-none opacity-40': isFetching }"
        class="flex space-x-2 bg-slate-200 px-10 text-xs"
    >
        <ProviderSearchResultsBreadcrumbsButton
            :q="modalQuery"
            :is-current="currentQuery.id === modalQuery.id"
            :include-arrow="false"
            @click="assignInitial()"
        />
        <div class="">
            <div v-for="crumb in initialCrumbs" :key="crumb.id" class="flex space-x-2">
                <ProviderSearchResultsBreadcrumbsButton
                    :q="crumb"
                    :is-current="currentQuery.id === crumb.id"
                    @click="assignInitial(crumb.id)"
                />

                <template v-for="child in findChildren(crumb.id)" :key="child.id">
                    <ProviderSearchResultsBreadcrumbsButton
                        :q="child"
                        :is-current="currentQuery.id === child.id"
                        @click="assignInitial(child.id)"
                    />

                    <template v-for="child2 in findChildren(child.id)" :key="child2.id">
                        <ProviderSearchResultsBreadcrumbsButton
                            :q="child2"
                            :is-current="currentQuery.id === child2.id"
                            @click="assignInitial(child2.id)"
                        />

                        <template v-for="child3 in findChildren(child2.id)" :key="child3.id">
                            <ProviderSearchResultsBreadcrumbsButton
                                :q="child3"
                                :is-current="currentQuery.id === child3.id"
                                @click="assignInitial(child3.id)"
                            />

                            <template v-for="child4 in findChildren(child3.id)" :key="child4.id">
                                <ProviderSearchResultsBreadcrumbsButton
                                    :q="child4"
                                    :is-current="currentQuery.id === child4.id"
                                    @click="assignInitial(child4.id)"
                                />

                                <template v-for="child5 in findChildren(child4.id)" :key="child5.id">
                                    <ProviderSearchResultsBreadcrumbsButton
                                        :q="child5"
                                        :is-current="currentQuery.id === child5.id"
                                        @click="assignInitial(child5.id)"
                                    />
                                </template>
                            </template>
                        </template>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useModalProviderSearch } from '@/composables/modalProviderSearch'

import ProviderSearchResultsBreadcrumbsButton from './ProviderSearchResultsBreadcrumbsButton.vue'

defineProps({
    isFetching: {
        type: Boolean,
        default: false,
    },
})

const { modalQuerySearches, assignInitial, currentQuery, modalQuery } = useModalProviderSearch()

const initialCrumbs = computed(() => {
    if (modalQuerySearches.value?.length) {
        return modalQuerySearches.value.filter((c) => c?.from === 'initial')
    }

    return []
})

function findChildren(parentId) {
    return modalQuerySearches.value.filter((c) => c?.from === parentId)
}
</script>
