const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

export default function useFormatter(value, showCents = false) {
    if (isNaN(value)) {
        return 'n/a'
    }

    if (!showCents) {
        value = Math.round(value)
        return formatter.format(value).slice(0, -'.00'.length)
    }

    return formatter.format(value)
}
