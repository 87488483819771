<template>
    <div class="h-screen w-full">
        <FormSignIn
            :error="error"
            :is-fetching="isFetching"
            :email="emailFromQuery"
            :action="'Sign In'"
            @submit="handleSubmit"
        />
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useRouteQuery } from '@vueuse/router'
import FormSignIn from '/src/components/forms/FormSignIn.vue'
import { useCurrentUserStore } from '/src/stores/currentUser'

const currentUser = useCurrentUserStore()
const router = useRouter()
const error = ref(null)
const isFetching = ref(false)
const emailFromQuery = useRouteQuery('email')

// FWD if already logged in
// if (currentUser.cognitoSession) {
//     router.replace('/')
// }

async function handleSubmit(payload) {
    // Ensure form can only be submitted once
    if (isFetching.value) {
        return
    }

    isFetching.value = true

    currentUser
        .signIn(payload)
        .then(async () => {
            if (currentUser.cognito.session) {
                router.replace('/')
            }
        })
        .catch((err) => {
            if (err.code === 'UserNotConfirmedException') {
                currentUser.cognito.resendConfirmationCode({ email: payload.email })
                router.push(`/user-confirmation?email=${encodeURIComponent(payload.email)}`)
            }
            error.value = err.message || err
        })
        .finally(() => {
            isFetching.value = false
        })
}
</script>
