import { ref } from 'vue'
import { useCurrentUserStore } from '@/stores/currentUser'

const isReady = ref(false)
const isOpen = ref(false)

export function useBeacon() {
    if (!window.Beacon) {
        var script = document.createElement('script')
        script.id = 'beacon'
        script.text = `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`
        document.head.appendChild(script)

        window.Beacon('config', {
            display: {
                horizontalOffset: 80,
            },
        })

        window.Beacon('on', 'close', () => (isOpen.value = false))

        window.Beacon('on', 'open', () => (isOpen.value = true))

        window.Beacon('init', import.meta.env.VITE_HELP_SCOUT_BEACON_ID)

        isReady.value = true
    }

    function open() {
        isOpen.value = true

        identify()

        window.Beacon('open')
    }

    function close() {
        isOpen.value = false
        window.Beacon('close')
    }

    function toggle() {
        if (isOpen.value) {
            close()

            return
        }

        open()
    }

    function identify() {
        const currentUser = useCurrentUserStore()

        window.Beacon('identify', {
            email: currentUser.email,
            name: currentUser.full_name,
        })
    }

    function launchArticle(articleId) {
        window.Beacon('article', articleId)
    }

    return {
        open,
        close,
        isOpen,
        isReady,
        toggle,
        launchArticle,
    }
}
