<template>
    <form
        :class="{
            'bg-brand-blue/10': !isFormError,
            'bg-rose-50': isFormError,
            'pointer-events-none opacity-40': isSaving,
        }"
        class="flex items-stretch border-t bg-brand-blue/10 p-2"
        @submit.prevent="saveTag"
    >
        <InputText ref="tagName" v-model="tag" />
        <button
            type="submit"
            class="flex w-12 items-center justify-center rounded-none bg-brand-blue/60 text-white focus:bg-brand-blue/90"
        >
            <ArrowPathIcon v-if="isSaving" class="h-6 w-6 animate-spin" />
            <ArrowRightCircleIcon v-else class="h-6 w-6" />
        </button>
    </form>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue'
import { ArrowPathIcon, ArrowRightCircleIcon } from '@heroicons/vue/20/solid'
import InputText from '../forms/InputText.vue'
import { useAPI } from '@/composables/api'
import { useModalProviderSearch } from '@/composables/modalProviderSearch'

const emit = defineEmits(['done'])

const { currentQuery } = useModalProviderSearch()

const { relationshipsAPI } = useAPI()

const tagName = ref()
const tag = ref(null)
const isFormError = ref(false)
const isSaving = ref(false)

async function saveTag() {
    if (!tag.value) {
        isFormError.value = true

        return
    }

    try {
        isSaving.value = true

        await nextTick()

        await relationshipsAPI(`/tags/ein/${currentQuery.value.q}`, {
            method: 'POST',
            body: [tag.value],
        })

        const tags = await reloadTags()

        tag.value = null

        emit('done', tags)
    } catch (error) {
        console.error(error.message)
        isFormError.value = true
    } finally {
        isSaving.value = false
    }
}

const reloadTags = async function () {
    return await relationshipsAPI(`/tags/ein/${currentQuery.value.q}`, {
        method: 'GET',
    })
}

onMounted(() => tagName.value.$el.focus())
</script>
