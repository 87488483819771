<template>
    <div class="h-screen w-full">
        <div v-if="!resetDone" class="flex h-full flex-col items-center justify-center sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img class="mx-auto h-12 w-auto" src="/serif_mark.svg?url" alt="Workflow" />
                <h2 class="mt-6 text-center font-brand text-3xl font-extrabold text-gray-800">
                    Complete Password Reset
                </h2>
                <p class="mt-2 text-center text-sm text-gray-600">
                    Or
                    {{ ' ' }}
                    <router-link
                        to="/sign-in"
                        class="font-medium text-brand-purple-dark text-opacity-80 hover:text-opacity-100"
                    >
                        return to signin
                    </router-link>
                </p>
            </div>
            <div v-if="error" class="sm:mx-auto sm:w-full sm:max-w-md">
                <AlertError class="mt-8 w-full border-2 border-red-200">
                    {{ error }}
                </AlertError>
            </div>
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div class="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
                    <form class="space-y-6" action="#" method="POST" @submit.prevent="handleSubmit">
                        <div v-if="$route.query.code">
                            <label for="code" class="block text-sm font-medium text-gray-700"> Reset Code </label>
                            <div class="mt-1">
                                <input
                                    id="code"
                                    v-model="confirmationCode"
                                    name="code"
                                    type="text"
                                    :placeholder="$route.query.code"
                                    disabled="true"
                                    class="block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-400 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>

                            <label for="email" class="mt-4 block text-sm font-medium text-gray-700"
                                >Email Address</label
                            >
                            <div class="mt-1">
                                <input
                                    id="email"
                                    v-model="email"
                                    name="email"
                                    type="text"
                                    class="block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>

                            <label for="pass" class="mt-4 block text-sm font-medium text-gray-700">
                                New Password
                            </label>
                            <div class="mt-1">
                                <input
                                    id="password"
                                    v-model="password"
                                    name="password"
                                    type="password"
                                    autocomplete="current-password"
                                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                class="flex w-full justify-center rounded-md border border-transparent bg-brand-purple-dark bg-opacity-80 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <TheSpinner v-if="isFetching" class="mr-3 h-5 w-5" />
                                <span v-else>Reset Password</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div v-else class="flex h-full flex-col items-center justify-center sm:px-6 lg:px-8">
            <img class="mx-auto mb-4 h-12 w-auto" src="/serif_mark.svg?url" alt="Workflow" />
            <div class="sm:mx-auto">
                <AlertSuccess> Your password has been successfully reset. </AlertSuccess>

                <router-link
                    to="/sign-in"
                    class="font-medium text-brand-purple-dark text-opacity-80 hover:text-opacity-100"
                >
                    <button
                        class="mt-4 flex w-full justify-center rounded-md border border-transparent bg-brand-purple-dark bg-opacity-80 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Return to signin
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useCurrentUserStore } from '/src/stores/currentUser'
import { useRoute } from 'vue-router'

import AlertError from '/src/components/alerts/AlertError.vue'
import TheSpinner from '@/components/TheSpinner.vue'
import AlertSuccess from '/src/components/alerts/AlertSuccess.vue'

const route = useRoute()
const error = ref(null)
const isFetching = ref(false)
const currentUser = useCurrentUserStore()
const resetDone = ref(false)

const email = ref(null)
const confirmationCode = ref(null)
const password = ref(null)

async function handleSubmit() {
    let payload = {
        email: email.value,
        confirmationCode: route.query.code,
        password: password.value,
    }
    isFetching.value = true

    currentUser
        .resetPassword(payload)
        // eslint-disable-next-line no-unused-vars
        .then((result) => {
            resetDone.value = true
        })
        .catch((err) => {
            error.value = err.message || err
        })
        .finally(() => {
            isFetching.value = false
        })
}

onMounted(() => {})
</script>
