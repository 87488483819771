import { defineStore } from 'pinia'

export const useRadiologyStore = defineStore('radiologyConstants', {
    state: () => {
        return {
            priceDisplayType: [
                { id: 1, name: 'Technical', modifier_filter: 'TC' },
                { id: 2, name: 'Professional', modifier_filter: '26' },
                { id: 3, name: 'Global', modifier_filter: null },
                { id: 4, name: 'All' },
            ],

            sitesOfService: [
                { id: 1, name: 'Only Facility', site_filter_key: 'is_outpatient' },
                { id: 2, name: 'Only Nonfacility', site_filter_key: 'is_office' },
                { id: 3, name: 'All Sites' },
            ],

            // data is mostly taken from https://www.kff.org/other/state-indicator/total-population/?dataView=1&currentTimeframe=0&sortModel=%7B%22colId%22:%22Location%22,%22sort%22:%22asc%22%7D
            codeSetMaps: {
                neuro: ['70551', '70552', '70553', '70450', '70460', '70470'],
                msk: ['72141', '72142', '72146', '72147', '72157'],
                body: ['74177', '74176', '74178', '70496', '70498', '71275'],
            },
            // adding regions and customers, but leaving ones we don't have data for commented out.
            availableRegions: [
                {
                    id: 1,
                    name: 'Alabama',
                    abbreviation: 'AL',
                    payerMix: {
                        commercial: 52.3,
                        medicare: 16.3,
                        medicaid: 20.8,
                        other: 10.6,
                    },
                },
                {
                    id: 2,
                    name: 'California',
                    abbreviation: 'CA',
                    payerMix: {
                        commercial: 54.6,
                        medicare: 11.4,
                        medicaid: 25.3,
                        other: 8.7,
                    },
                },
                {
                    id: 3,
                    name: 'Delaware',
                    abbreviation: 'DE',
                    payerMix: {
                        commercial: 53.8,
                        medicare: 17.3,
                        medicaid: 20.4,
                        other: 8.5,
                    },
                },
                {
                    id: 4,
                    name: 'District of Columbia',
                    abbreviation: 'DC',
                    payerMix: {
                        commercial: 63.3,
                        medicare: 8.5,
                        medicaid: 24.1,
                        other: 4.1,
                    },
                },
                {
                    id: 5,
                    name: 'Georgia',
                    abbreviation: 'GA',
                    payerMix: {
                        commercial: 54.5,
                        medicare: 12.6,
                        medicaid: 17.3,
                        other: 15.6,
                    },
                },
                {
                    id: 6,
                    name: 'Hawaii',
                    abbreviation: 'HI',
                    payerMix: {
                        commercial: 58.4,
                        medicare: 15.9,
                        medicaid: 17.6,
                        other: 8.1,
                    },
                },
                {
                    id: 7,
                    name: 'Illinois',
                    abbreviation: 'IL',
                    payerMix: {
                        commercial: 59.8,
                        medicare: 14.1,
                        medicaid: 18.2,
                        other: 8.0,
                    },
                },
                {
                    id: 8,
                    name: 'Indiana',
                    abbreviation: 'IN',
                    payerMix: {
                        commercial: 57.7,
                        medicare: 14.8,
                        medicaid: 17.7,
                        other: 9.8,
                    },
                },
                {
                    id: 9,
                    name: 'Kentucky',
                    abbreviation: 'KY',
                    payerMix: {
                        commercial: 49.5,
                        medicare: 15.6,
                        medicaid: 14.2,
                        other: 6.6,
                    },
                },

                {
                    id: 10,
                    name: 'Maryland',
                    abbreviation: 'MD',
                    payerMix: {
                        commercial: 60.1,
                        medicare: 13.3,
                        medicaid: 18.7,
                        other: 7.9,
                    },
                },

                {
                    id: 11,
                    name: 'Missouri',
                    abbreviation: 'MO',
                    payerMix: {
                        commercial: 57.6,
                        medicare: 16.6,
                        medicaid: 16.0,
                        other: 9.7,
                    },
                },

                {
                    id: 12,
                    name: 'New Hampshire',
                    abbreviation: 'NH',
                    payerMix: {
                        commercial: 61.5,
                        medicare: 17.7,
                        medicaid: 13.2,
                        other: 7.6,
                    },
                },
                {
                    id: 13,
                    name: 'North Carolina',
                    abbreviation: 'NC',
                    payerMix: {
                        commercial: 53,
                        medicare: 15.3,
                        medicaid: 17.9,
                        other: 13.8,
                    },
                },

                {
                    id: 14,
                    name: 'Ohio',
                    abbreviation: 'OH',
                    payerMix: {
                        commercial: 55.8,
                        medicare: 16.3,
                        medicaid: 21.3,
                        other: 6.6,
                    },
                },
                {
                    id: 15,
                    name: 'Oregon',
                    abbreviation: 'OR',
                    payerMix: {
                        commercial: 53.7,
                        medicare: 16.3,
                        medicaid: 23.3,
                        other: 6.7,
                    },
                },
                {
                    id: 16,
                    name: 'Pennsylvania',
                    abbreviation: 'PA',
                    payerMix: {
                        commercial: 56.9,
                        medicare: 16.3,
                        medicaid: 20.2,
                        other: 6.6,
                    },
                },
                {
                    id: 17,
                    name: 'South Carolina',
                    abbreviation: 'SC',
                    payerMix: {
                        commercial: 51.5,
                        medicare: 16.8,
                        medicaid: 18.8,
                        other: 12.9,
                    },
                },

                {
                    id: 18,
                    name: 'Texas',
                    abbreviation: 'TX',
                    payerMix: {
                        commercial: 53.9,
                        medicare: 11.1,
                        medicaid: 16.8,
                        other: 18.2,
                    },
                },
                {
                    id: 19,
                    name: 'Washington',
                    abbreviation: 'WA',
                    payerMix: {
                        commercial: 57.6,
                        medicare: 14.1,
                        medicaid: 20.7,
                        other: 7.7,
                    },
                },
                {
                    id: 20,
                    name: 'Wisconsin',
                    abbreviation: 'WI',
                    payerMix: {
                        commercial: 61.4,
                        medicare: 16.6,
                        medicaid: 18.0,
                        other: 6,
                    },
                },
            ],
            availableCustomers: [
                {
                    id: 1,
                    name: 'Annapolis Radiology Associates',
                    state: 'MD',
                    ein: '521467734',
                },
                {
                    id: 2,
                    name: 'Associated Radiologists Pa - Meritus Medical Center',
                    state: 'MD',
                    ein: '520607949',
                },
                {
                    id: 3,
                    name: 'Associated Radiologists Pa - Fulton County Medical Center',
                    state: 'PA',
                    ein: '231401561',
                },
                {
                    id: 4,
                    name: 'Athens Radiology Associates - STM Good Samaritan Greensboro',
                    state: 'GA',
                    ein: '261720984',
                },
                {
                    id: 5,
                    name: 'Athens Radiology Associates - Piedmont Athens Regional',
                    state: 'GA',
                    ein: '582179986',
                },
                {
                    id: 6,
                    name: 'Athens Radiology Associates - STM Sacred Heart Lavonia',
                    state: 'GA',
                    ein: '473752176',
                },
                {
                    id: 7,
                    name: 'Athens Radiology Associates - Piedmont Walton',
                    state: 'GA',
                    ein: '824194264',
                },
                {
                    id: 8,
                    name: 'Athens Radiology Associates - STM Athens',
                    state: 'GA',
                    ein: '580566223',
                },
                {
                    id: 9,
                    name: 'Aurora Radiology Consultants SC - NW Valley West',
                    state: 'IL',
                    ein: '237087041',
                },
                {
                    id: 10,
                    name: 'Aurora Radiology Consultants SC - NW Kishwaukee',
                    state: 'IL',
                    ein: '237087041',
                },
                {
                    id: 11,
                    name: 'Birmingham Radiological Group',
                    state: 'AL',
                    ein: '631058174',
                },
                {
                    id: 12,
                    name: 'Carolina Radiological Associates',
                    state: 'SC',
                    ein: '232791808',
                },
                {
                    id: 13,
                    name: 'Catawba Radiology - Catawba Valley Medical Center',
                    state: 'NC',
                    ein: '560789196',
                },
                {
                    id: 14,
                    name: 'Catawba Radiology - Frye Regional Medical Center',
                    state: 'NC',
                    ein: '560789196',
                },
                {
                    id: 15,
                    name: 'Central Illinois Radiological Associates Ltd - Riverside Medical Center',
                    state: 'IL',
                    ein: '362414944',
                },
                {
                    id: 16,
                    name: 'Central Illinois Radiological Associates Ltd - St Mary Streator',
                    state: 'IL',
                    ein: '362169181',
                },
                {
                    id: 17,
                    name: 'Central Illinois Radiological Associates Ltd - OSF Little Company of Mary',
                    state: 'IL',
                    ein: '370813229',
                },
                {
                    id: 18,
                    name: 'Central Illinois Radiological Associates Ltd - St Margarets Spring Valley',
                    state: 'IL',
                    ein: '362167884',
                },
                {
                    id: 19,
                    name: 'Central Illinois Radiological Associates Ltd - St Margarets Peru',
                    state: 'IL',
                    ein: '362852553',
                },
                {
                    id: 20,
                    name: 'Doylestown Radiology Associates',
                    state: 'PA',
                    ein: '231352174',
                },
                {
                    id: 21,
                    name: 'Durham Radiology Associates',
                    state: 'NC',
                    ein: '560593547',
                },
                {
                    id: 22,
                    name: 'Elmhurst Radiologists SC',
                    state: 'IL',
                    ein: '362169147',
                },
                {
                    id: 23,
                    name: 'Frederick Health Hospital',
                    state: 'MD',
                    ein: '520591612',
                },
                {
                    id: 24,
                    name: 'Georgetown Radiology LLC - Waccamaw',
                    state: 'SC',
                    ein: '043633263',
                },
                {
                    id: 25,
                    name: 'Georgetown Radiology LLC - Georgetown',
                    state: 'SC',
                    ein: '570341194',
                },
                {
                    id: 26,
                    name: 'Grundy Radiologists Inc',
                    state: 'IL',
                    ein: '362170155',
                },
                {
                    id: 27,
                    name: "Hawai'i Pacific Health - HPH Kapiolani",
                    state: 'HI',
                    ein: '990177350',
                },
                {
                    id: 28,
                    name: "Hawai'i Pacific Health - HPH Wilcox",
                    state: 'HI',
                    ein: '990074365',
                },
                {
                    id: 29,
                    name: "Hawai'i Pacific Health - HHSC Kauai Veterans Memorial Hospital",
                    state: 'HI',
                    ein: '990262194',
                },
                {
                    id: 30,
                    name: "Hawai'i Pacific Health - HHSC Samuel Mahelona Memorial Hospital",
                    state: 'HI',
                    ein: '990262217',
                },
                {
                    id: 31,
                    name: "Hawai'i Pacific Health - HPH Straub",
                    state: 'HI',
                    ein: '912151670',
                },
                {
                    id: 32,
                    name: 'Hendricks County Radiology Inc - Putnam County',
                    state: 'IN',
                    ein: '351140835',
                },
                {
                    id: 33,
                    name: 'Hendricks County Radiology Inc - Brownsburg Hospital',
                    state: 'IN',
                    ein: '351361243',
                },
                {
                    id: 34,
                    name: 'Hendricks County Radiology Inc - Hendricks Regional Health',
                    state: 'IN',
                    ein: '351361243',
                },
                {
                    id: 35,
                    name: 'Johns Hopkins Howard County',
                    state: 'MD',
                    ein: '522093120',
                },
                { id: 36, name: 'Johns Hopkins Sibley', state: 'DC', ein: '530196602' },
                {
                    id: 37,
                    name: 'Loyola University Medical Center',
                    state: 'IL',
                    ein: '363332852',
                },
                {
                    id: 38,
                    name: 'Marshall Radiology LLC - Marshall South',
                    state: 'AL',
                    ein: '831651180',
                },
                {
                    id: 39,
                    name: 'Marshall Radiology LLC - Marshall North',
                    state: 'AL',
                    ein: '831651180',
                },
                {
                    id: 40,
                    name: 'Medical Imaging Consultants, LLP - CHRISTUS Longview',
                    state: 'TX',
                    ein: '750974351',
                },
                {
                    id: 41,
                    name: 'Medical Imaging Consultants, LLP - CHRISTUS Marshall',
                    state: 'TX',
                    ein: '750974351',
                },
                {
                    id: 42,
                    name: 'Midtown Medical Imaging, LLC - Alliance Midtown Medical',
                    state: 'TX',
                    ein: '231352191',
                },
                {
                    id: 43,
                    name: 'Midtown Medical Imaging, LLC - Midtown Southlake',
                    state: 'TX',
                    ein: '020555370',
                },
                {
                    id: 44,
                    name: 'Naperville Radiologists Sc',
                    state: 'IL',
                    ein: '363297173',
                },
                {
                    id: 45,
                    name: 'Northside Hospital System - Northside Hospital Duluth',
                    state: 'GA',
                    ein: '582002413',
                },
                {
                    id: 46,
                    name: 'Northside Hospital System - Northside Hospital Gwinnett',
                    state: 'GA',
                    ein: '581954432',
                },
                {
                    id: 47,
                    name: 'Northwest Radiologists - PeaceHealth United',
                    state: 'WA',
                    ein: '371715059',
                },
                {
                    id: 48,
                    name: 'Northwest Radiologists - PeaceHealth Peace Island',
                    state: 'WA',
                    ein: '383868360',
                },
                {
                    id: 49,
                    name: 'Northwest Radiology Associates',
                    state: 'IL',
                    ein: '363399794',
                },
                { id: 50, name: 'NW Central Dupage', state: 'IL', ein: '362340313' },
                { id: 51, name: 'NW Delnor', state: 'IL', ein: '363484281' },
                {
                    id: 52,
                    name: 'Oregon Health and Science University',
                    state: 'OR',
                    ein: '930430029',
                },
                {
                    id: 53,
                    name: 'Pacific Radiology Group',
                    state: 'HI',
                    ein: '990274038',
                },
                {
                    id: 54,
                    name: 'Peninsula Radiology - Atlantic General Hospital',
                    state: 'MD',
                    ein: '521656507',
                },
                {
                    id: 55,
                    name: 'Peninsula Radiology - TidalHealth Nanticoke',
                    state: 'MD',
                    ein: '510069243',
                },
                {
                    id: 56,
                    name: 'Peninsula Radiology - TidalHealth Peninsula',
                    state: 'MD',
                    ein: '520591628',
                },
                {
                    id: 57,
                    name: 'Piedmont South Imaging - Piedmont Newnan Hospital',
                    state: 'GA',
                    ein: '205077249',
                },
                {
                    id: 58,
                    name: 'Piedmont South Imaging - Piedmont Fayette Hospital',
                    state: 'GA',
                    ein: '582322328',
                },
                {
                    id: 59,
                    name: 'Pinehurst Radiology Group PA',
                    state: 'NC',
                    ein: '561936354',
                },
                {
                    id: 60,
                    name: 'Radiologists Of Dupage',
                    state: 'IL',
                    ein: '362169147',
                },
                {
                    id: 61,
                    name: 'Radiology Associates Of Atlanta',
                    state: 'GA',
                    ein: '580566213',
                },
                {
                    id: 62,
                    name: 'Radiology Associates of Wausau - Aspirus Wausau Hospital',
                    state: 'WI',
                    ein: '391138241',
                },
                {
                    id: 63,
                    name: 'Radiology Associates of Wausau - Aspirus Plover',
                    state: 'WI',
                    ein: '391138241',
                },
                {
                    id: 64,
                    name: 'Radiology Chartered - Oconto Hospital',
                    state: 'WI',
                    ein: '061745397',
                },
                {
                    id: 65,
                    name: 'Radiology Chartered - Bellin Hospital',
                    state: 'WI',
                    ein: '390884478',
                },
                {
                    id: 66,
                    name: 'Radiology Incorporated - NWH Starke',
                    state: 'IN',
                    ein: '310796089',
                },
                {
                    id: 67,
                    name: 'Radiology Incorporated - Memorial',
                    state: 'IN',
                    ein: '350868132',
                },
                {
                    id: 68,
                    name: 'Radiology Incorporated - Beacon Three Rivers Hospital',
                    state: 'IN',
                    ein: '382317300',
                },
                {
                    id: 69,
                    name: 'Radiology Incorporated - Goshen General Hospital',
                    state: 'IN',
                    ein: '356001540',
                },
                {
                    id: 70,
                    name: 'Radiology Incorporated - NWH LaPorte',
                    state: 'IN',
                    ein: '810722737',
                },
                {
                    id: 71,
                    name: 'Radiology Incorporated - Franciscan Beacon',
                    state: 'IN',
                    ein: '832457155',
                },
                {
                    id: 72,
                    name: 'Radiology Incorporated - Beacon Granger Hospital',
                    state: 'IN',
                    ein: '350868132',
                },
                {
                    id: 73,
                    name: 'Radiology Incorporated - Elkhart',
                    state: 'IN',
                    ein: '350877574',
                },
                {
                    id: 74,
                    name: 'Radiology Specialists of St. Joseph, PC - Mosaic Life Care',
                    state: 'MO',
                    ein: '440545289',
                },
                {
                    id: 75,
                    name: 'Rockford Radiology Associates, P.C. - KSB',
                    state: 'IL',
                    ein: '361000540',
                },
                {
                    id: 76,
                    name: 'Roper Radiologists Pa - Roper Mount Pleasant',
                    state: 'SC',
                    ein: '570360499',
                },
                {
                    id: 77,
                    name: 'Roper Radiologists Pa - Roper Berkeley Hospital',
                    state: 'SC',
                    ein: '263710229',
                },
                {
                    id: 78,
                    name: 'Roper Radiologists Pa - Roper Hospital',
                    state: 'SC',
                    ein: '570828733',
                },
                {
                    id: 79,
                    name: 'Sampson Regional Medical Center',
                    state: 'NC',
                    ein: '560562304',
                },
                {
                    id: 80,
                    name: 'South Gwinnett Radiology',
                    state: 'GA',
                    ein: '800023773',
                },
                { id: 81, name: 'Stanford Health Care', state: 'CA', ein: '770465765' },
                {
                    id: 82,
                    name: 'Stephen W. Brown and Radiology Associates of Augusta LLP - Burke Medical Center',
                    state: 'GA',
                    ein: '853939331',
                },
                {
                    id: 83,
                    name: 'Stephen W. Brown and Radiology Associates of Augusta LLP - Doctors Hospital',
                    state: 'GA',
                    ein: '043775926',
                },
                {
                    id: 84,
                    name: 'Stephen W. Brown and Radiology Associates of Augusta LLP - Jenkins County Medical Center',
                    state: 'GA',
                    ein: '273100894',
                },
                {
                    id: 85,
                    name: 'Stephen W. Brown and Radiology Associates of Augusta LLP - Piedmont Augusta Hospital',
                    state: 'GA',
                    ein: '581581103',
                },
                {
                    id: 86,
                    name: 'Stephen W. Brown and Radiology Associates of Augusta LLP - Piedmont McDuffie Hospital',
                    state: 'GA',
                    ein: '454166209',
                },
                {
                    id: 87,
                    name: 'The Ohio State University Hospital',
                    state: 'OH',
                    ein: '311340739',
                },
                {
                    id: 88,
                    name: 'Torrance Radiology Medical Group',
                    state: 'CA',
                    ein: '951644042',
                },
                {
                    id: 89,
                    name: 'Tualatin Imaging, PC - Good Shepherd Medical Center',
                    state: 'OR',
                    ein: '930425580',
                },
                {
                    id: 90,
                    name: 'Tualatin Imaging, PC - Willamette Valley Medical Center',
                    state: 'OR',
                    ein: '621762552',
                },
                {
                    id: 91,
                    name: 'Tualatin Imaging, PC - Legacy Health Silverton',
                    state: 'OR',
                    ein: '930281321',
                },
                {
                    id: 92,
                    name: 'University of Kentucky',
                    state: 'KY',
                    ein: '616001218',
                },
                {
                    id: 93,
                    name: 'Valley Imaging Consultants, LLC - Rush Copley',
                    state: 'IL',
                    ein: '362170840',
                },
                {
                    id: 94,
                    name: 'Valley Imaging Consultants, LLC - Saint Anthony Hospital',
                    state: 'IL',
                    ein: '510217097',
                },
                {
                    id: 95,
                    name: 'Valley Radiology Associates',
                    state: 'NC',
                    ein: '262971169',
                },
                {
                    id: 96,
                    name: 'Wake Forest Baptist Medical Center - Alleghany Memorial Hospital',
                    state: 'NC',
                    ein: '560525657',
                },
                {
                    id: 97,
                    name: 'AMITA Aurora',
                    state: 'IL',
                    ein: '364195126',
                },
                {
                    id: 98,
                    name: 'Lexington Radiology Associates',
                    state: 'SC',
                    ein: '852276567',
                },

                {
                    id: 99,
                    name: 'Lexington Radiology Associates',
                    state: 'SC',
                    ein: '852276567',
                },

                {
                    id: 100,
                    name: 'Mercy Fitzgerald Hospital',
                    state: 'PA',
                    ein: '231352191',
                },
                {
                    id: 101,
                    name: 'Mid America Radiology SC',
                    state: 'IL',
                    ein: '370792770',
                },
                {
                    id: 102,
                    name: 'Nash X-Ray Associates',
                    state: 'NC',
                    ein: '561173082',
                },
                {
                    id: 103,
                    name: 'Seacoast Radiology Pa',
                    state: 'NH',
                    ein: '020260334',
                },

                {
                    id: 104,
                    name: 'Radiology Affiliates Imaging (RAI)',
                    state: 'DE',
                    ein: '221917247',
                },
                {
                    id: 105,
                    name: 'OSF Healthcare System',
                    state: 'IL',
                    ein: '370813229',
                },
                {
                    id: 106,
                    name: 'Graham Hospital',
                    state: 'IL',
                    ein: '370673506',
                },
                {
                    id: 107,
                    name: 'Riverside Medical Center',
                    state: 'IL',
                    ein: '362414944',
                },
                {
                    id: 108,
                    name: 'St. Margarets Spring Valley',
                    state: 'IL',
                    ein: '362167884',
                },
                {
                    id: 109,
                    name: 'St. Margarets Peru',
                    state: 'IL',
                    ein: '362852553',
                },
                {
                    id: 110,
                    name: "The Queen's Medical Center",
                    state: 'HI',
                    ein: '990073524',
                },
                {
                    id: 111,
                    name: 'Adventist Health Castle',
                    state: 'HI',
                    ein: '990107330',
                },
                {
                    id: 112,
                    name: 'Orange Coast Memorial Medical Center',
                    state: 'CA',
                    ein: '943223279',
                },
                {
                    id: 113,
                    name: 'Saddleback Memorial Medical Center',
                    state: 'CA',
                    ein: '952585792',
                },
                {
                    id: 114,
                    name: 'MUSC Lancaster',
                    state: 'SC',
                    ein: '571098556',
                },
                {
                    id: 115,
                    name: 'MUSC Orangeburg',
                    state: 'SC',
                    ein: '576008010',
                },
                {
                    id: 116,
                    name: 'Ascension St. Alexius',
                    state: 'IL',
                    ein: '362596381',
                },
            ],
        }
    },
})
