<template>
    <div class="flex flex-none basis-full flex-col">
        <PageHeading> Radiology Dashboard </PageHeading>
        <GutteredBlock class="mb-20 flex flex-col" :class="Object.values(prices).length ? 'flex-auto' : ''">
            <div class="flex flex-row align-middle">
                <div class="flex basis-1/4 flex-col items-center justify-center">
                    <ControlLabel>Region:</ControlLabel>
                    <SelectControl
                        v-model="region"
                        :data="radiologyConstants.availableRegions"
                        class="mx-4 w-56"
                        @update:model-value="availableCustomers"
                    />
                </div>
                <div class="flex basis-1/2 flex-col items-center justify-center">
                    <ControlLabel>Customer:</ControlLabel>
                    <SelectControl
                        v-model="customer"
                        :data="availableCustomers"
                        class="mx-4 w-full"
                        @update:model-value="fetchData()"
                    />
                </div>
                <div class="basis-1/8 flex flex-col items-center justify-center">
                    <ControlLabel>Rate Component:</ControlLabel>
                    <SelectControl
                        v-model="priceDisplayType"
                        :data="radiologyConstants.priceDisplayType"
                        class="mx-4 w-40"
                        @update:model-value="extractPricesFromContracts()"
                    />
                </div>
                <div class="basis-1/8 flex flex-col items-center justify-center">
                    <ControlLabel>Sites Of Service:</ControlLabel>
                    <SelectControl
                        v-model="siteOfService"
                        :data="radiologyConstants.sitesOfService"
                        class="mx-4 w-40"
                        @update:model-value="extractPricesFromContracts()"
                    />
                </div>
            </div>

            <TheSpinner v-if="isFetching" class="mx-auto mt-24 h-24 w-24 opacity-60" />
            <div v-if="Object.values(prices).length" class="mt-8 flex-auto">
                <div class="h-auto overflow-y-scroll pb-8">
                    <DataTable v-for="(key, i) in Object.keys(prices)" :key="i" class="mt-5">
                        <template #headers>
                            <div class="col-span-2">{{ key }} Code</div>
                            <div class="col-span-1">Modifier</div>
                            <div class="col-span-5">Description</div>
                            <div class="col-span-4">Rate</div>
                            <div class="col-span-4">Relative to CMS</div>
                        </template>
                        <DataTableItem v-for="(price, index) in prices[key]" :key="index">
                            <div class="col-span-2 font-medium">
                                {{ price.code }}
                            </div>
                            <div class="col-span-1 truncate text-left hover:overflow-visible">
                                {{ price.modifier_list }}
                            </div>
                            <div class="col-span-5 font-medium">
                                {{ price.description }}
                            </div>
                            <div class="col-span-4">
                                {{ useFormatter(price.rate, true) }}
                            </div>
                            <div class="col-span-4">
                                {{
                                    isNaN(price.rateRel)
                                        ? 'No Baseline Available'
                                        : price.rateRel?.toLocaleString('en-US', {
                                              style: 'percent',
                                              maximumFractionDigits: 1,
                                          })
                                }}
                            </div>
                        </DataTableItem>
                    </DataTable>
                </div>
            </div>
            <div v-else class="flex h-96 items-center justify-center">
                <div class="font-brand text-xl font-bold leading-6">Please select an organization to get started.</div>
            </div>

            <PayerMix
                v-if="Object.keys(prices).length"
                :key="prices"
                class=""
                :commercial-average-price="commercialAverage"
                :commercial-default-percentage="
                    region.payerMix.commercial /
                    (region.payerMix.commercial + region.payerMix.medicare + region.payerMix.medicaid)
                "
                :medicare-average-price="medicareAverage"
                :medicare-default-percentage="
                    region.payerMix.medicare /
                    (region.payerMix.commercial + region.payerMix.medicare + region.payerMix.medicaid)
                "
                :medicaid-average-price="medicaidAverage"
                :medicaid-default-percentage="
                    region.payerMix.medicaid /
                    (region.payerMix.commercial + region.payerMix.medicare + region.payerMix.medicaid)
                "
            />
        </GutteredBlock>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import PageHeading from '@/components/page/PageHeading.vue'
import GutteredBlock from '@/components/page/GutteredBlock.vue'
import ControlLabel from '@/components/forms/ControlLabel.vue'
import DataTable from '@/components/tables/DataTable.vue'
import DataTableItem from '@/components/tables/DataTableItem.vue'
import SelectControl from '@/components/forms/SelectControl.vue'
import TheSpinner from '@/components/TheSpinner.vue'

import { useRadiologyStore } from '@/stores/radiologyConstants'
import PayerMix from '@/components/calc/PayerMix.vue'
import useFormatter from '@/utilities/usdFormatter.js'
import { useAPI } from '@/composables/api'

const radiologyConstants = useRadiologyStore()
const { payerAPI, lookupAPI } = useAPI()

const availableCustomers = computed(() => {
    let baseSet = radiologyConstants.availableCustomers
    if (region.value?.abbreviation) {
        baseSet = baseSet.filter((i) => i.state === region.value.abbreviation)
    }

    return baseSet
})

const isFetching = ref(false)
const region = ref({ id: null, value: null })
const customer = ref({ id: null, value: null })

// these defaults were chosen by the customer
const priceDisplayType = ref(radiologyConstants.priceDisplayType[1])
const siteOfService = ref(radiologyConstants.sitesOfService[0])

const contracts = ref([])
const descriptions = ref([])
const prices = ref({})
const priceKeys = ref(Object.keys(radiologyConstants.codeSetMaps))

const applyPriceDisplayTypeFilter = function (item) {
    if ('modifier_filter' in priceDisplayType.value) {
        return item.modifier_list === priceDisplayType.value.modifier_filter
    }

    return item
}

const commercialAverage = computed(() => {
    let count = 0
    let priceAgg = 0

    function aggregator(f) {
        if (f.rate) {
            count++
            priceAgg += f.rate
        }
    }
    for (const priceKey of priceKeys.value) {
        prices.value[priceKey].map(aggregator)
    }

    return priceAgg / count
})

const medicareAverage = computed(() => {
    let count = 0
    let priceAgg = 0

    function aggregator(f) {
        if (f.baseline) {
            count++
            priceAgg += f.baseline
        }
    }

    for (const priceKey of priceKeys.value) {
        prices.value[priceKey].map(aggregator)
    }

    return priceAgg / count
})

const medicaidAverage = computed(() => {
    return medicareAverage.value * 0.95
})

function applyCMSBaseline(price) {
    price.baseline = price.cms_baseline_rate
    price.rateRel = price.rate / price.cms_baseline_rate

    return price
}

const applyDescription = function (price) {
    price.description = descriptions.value.find((d) => d.code === price.code).title
    return price
}

const mergePrices = function (acc, curr) {
    // base case
    if (acc.length === 0) {
        curr.count = 1
        return [curr]
    }

    let last = acc.pop()

    // this does an in-place average if we've already seen code and modifier
    if (last && last.code === curr.code && last.modifier_list === curr.modifier_list) {
        last.rate = (last.count * last.rate + curr.rate) / (last.count + 1)
        last.count = last.count + 1
        acc.push(last)
    } else {
        // or appends curr if it's different
        acc.push(last)
        curr.count = 1
        acc.push(curr)
    }
    return acc
}

function extractPricesFromContracts() {
    prices.value = {}
    for (const priceKey of priceKeys.value) {
        prices.value[priceKey] = []
    }

    for (const priceKey of priceKeys.value) {
        for (const contract of contracts.value) {
            if (radiologyConstants.codeSetMaps[priceKey].includes(contract.code)) {
                if (siteOfService.value.site_filter_key) {
                    if (
                        siteOfService.value.site_filter_key === 'is_outpatient' &&
                        !contract.place_of_service_list.includes('22')
                    )
                        continue

                    if (
                        siteOfService.value.site_filter_key === 'is_office' &&
                        !contract.place_of_service_list.includes('11')
                    )
                        continue
                }
                prices.value[priceKey].push(contract)
            }
        }
    }

    for (const priceKey of priceKeys.value) {
        prices.value[priceKey] = prices.value[priceKey]
            .filter(applyPriceDisplayTypeFilter)
            .sort((a, b) => {
                if (a.code.localeCompare(b.code) === 0) {
                    if (!a.modifier_list) return b.modifier_list ? -1 : 0
                    if (!b.modifier_list) return 1
                    return a.modifier_list.localeCompare(b.modifier_list)
                }
                return a.code.localeCompare(b.code)
            })
            .reduce(mergePrices, [])
            .map(applyCMSBaseline)
            .map(applyDescription)
    }
}

const fetchData = async () => {
    isFetching.value = true
    try {
        let einQuery = payerAPI(`/contracts/ein/${customer.value.ein}?tier=professional`)

        let codeset = Object.values(radiologyConstants.codeSetMaps).flat()

        let descriptionsQuery = lookupAPI(`/codesets`, {
            method: 'POST',
            body: {
                codes: codeset,
            },
        })

        let [contractResponse, descriptionsResponse] = await Promise.all([einQuery, descriptionsQuery])

        contracts.value = []
        for (const contract of contractResponse.contracts) {
            contracts.value.push(contract)
        }

        descriptions.value = descriptionsResponse

        extractPricesFromContracts()
    } catch (error) {
        console.error(error.message)
    } finally {
        isFetching.value = false
    }
}
</script>
